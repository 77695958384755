import { resolve } from "node:path/win32";
import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AdminClient, ConnectResponse, UserClient } from "../services";
import { ITokenManager } from "../services/ITokenManager";
import { useLocation } from "react-router-dom";

export interface UserData {
  email: string;
  token: string;
  renewToken: string;
  firstName: string;
  role: string

}
export interface AppContextInterface extends ITokenManager {
  userData: UserData | undefined
  onLogout(): Promise<void>;
  loading: boolean
  isAuthenticated: boolean,
  onLogin(newAuthData?: UserData): Promise<void>;

}
const asyncFunc: () => Promise<void> = async () => {
  await new Promise<void>(resolve => resolve());
};

export const AuthDataContext = createContext<AppContextInterface>({
  userData: undefined,
  isAuthenticated: false,
  loading: true,
  getToken: (): string => { return "" },
  onLogin: (newAuthData?: UserData) => Promise.resolve(),
  onLogout: () => Promise.resolve(),
  renewTokenSilently: () => Promise.resolve()

})


export interface AuthDataProviderProps {

}


const initialAuthData: UserData = {} as UserData;
const AuthDataProvider = (props: AuthDataProviderProps) => {
  const [userData, setUserData] = useState<UserData>({} as UserData);
  const [loading, setLoading] = useState<boolean>(true);
  var managToken: ITokenManager = useAuthDataContext();
  const _client = new AdminClient()
  const token = useRef<string>("");
  const location = useLocation()

  useEffect(() => {

    const init = async () => {
      renewTokenSilently()
        .then(() => console.log("token renewed"))
        .catch((err) => {
          console.error(err)
          logoutAndRedirect()
        })
        .finally(() => setLoading(false));
    }
    init();
  }, []);
  useEffect(() => {
    token.current = userData.token
    return () => {
    }
  }, [userData.token])

  const onLogout = (): Promise<void> => {
    localStorage.removeItem("renewToken");
    setUserData({} as UserData);
    return Promise.resolve();
  }

  const logoutAndRedirect = () => onLogout().then(() => location.pathname = '/login')

  const onLogin = (newAuthData: UserData): Promise<void> => {
    setLoading(true);
    localStorage.setItem("renewToken", newAuthData.renewToken)
    setUserData(newAuthData as UserData);
    setLoading(false);
    return Promise.resolve();
  }

  const getToken = (): string => {

    return token.current;
  }

  const renewTokenSilently = async () => {
    await _client.reconnect(localStorage.getItem("renewToken") ?? "")
      .then((res: any) => {
        token.current = res?.payload?.token;
        setUserData({ email: res?.payload?.email ?? "", token: res?.payload?.token ?? "", renewToken: res?.payload?.renewToken ?? "", role: (res.payload?.sa ? "superadmin" : ""), firstName: res?.payload?.firstName ?? "" });
      })
      .catch(() => {
        logoutAndRedirect()
      })

  }
  const isAuthenticated = useMemo((): boolean => {
    if (userData?.email && userData?.token) return true;
    return false;
  }, [userData?.email, userData.renewToken])

  const authDataValue: AppContextInterface = useMemo(() => {

    return { loading: loading, userData: userData, onLogin, onLogout, isAuthenticated, getToken, renewTokenSilently }
  }
    , [userData?.email, token.current, loading]);

  return <AuthDataContext.Provider value={authDataValue} {...props} />;

};

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthDataProvider;

