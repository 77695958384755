import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Page } from 'react-pdf'
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { SizeMe } from 'react-sizeme';
import { useAuthDataContext } from '../../core/AuthDataProvider';
import { AdminCandidateClient, CandidateClient } from '../../services';
import { ITokenManager } from '../../services/ITokenManager';
import "./index.scss";
export interface IShowShowCVCandidateProps {
    candidateId: string
    onClose: () => void;
}

export function ShowCVCandidate({ candidateId, onClose }: IShowShowCVCandidateProps) {

    var _client = new AdminCandidateClient()
    const [pdf, setPdf] = useState<any>()
    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [pdfLoaded, setPdfLoaded] = useState(false)
    const [pdfError, setPdfError] = useState(false)

    useEffect(() => {

        const pdf = async () => _client.downloadCV(candidateId);

        pdf().then((response) => {
            treatPdf(response.data).catch(console.error);

        }).catch(console.error);

    }, [])
    async function treatPdf(res: Blob) {
        let a = await res.arrayBuffer()
        setPdf(a)
    }

    const downloadCV = async () => {
        const response = await _client.downloadCV(candidateId);
        const file = new Blob([response.data], { type: pdfLoaded ? 'application/pdf' : "application/msword" });
        const fileURL = URL.createObjectURL(file);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;

        fileLink.setAttribute('download', (response?.fileName ?? candidateId));
        document.body.appendChild(fileLink);
        fileLink.click();
    }
    function onDocumentLoadSuccess({ numPages }: any) {

        setNumPages(numPages);
        setPdfLoaded(true)
    }

    return (<>
        <Modal show={true} size="lg" fullscreen="lg-down" onHide={() => onClose()}>

            <Modal.Header closeButton>
                <Modal.Title>CV du candidat</Modal.Title>
            </Modal.Header>

            <Modal.Body className="height-80 scollable">
                <SizeMe>
                    {({ size }) => (

                        <Document className="w-100"
                            file={pdf}//file="http://localhost:5000/ext/testPDF.pdf"
                            onLoadSuccess={onDocumentLoadSuccess}
                            noData={
                                <div className="noPDF">
                                    Un résumé sera bientôt ici

                                </div>
                            }
                            error={<div className="alert alert-warning text-center">Le CV du candidat ne peut être pré-visualisé, essayer de télécharger le CV<br /><br />
                                <Button variant="primary" onClick={() => downloadCV()}>Télécharger</Button>
                            </div>}

                            onLoadError={(e) => {
                                console.log(e);
                                setPdfError(true);
                            }}
                        >
                            {Array.apply(null, Array(numPages))
                                .map((x, i) => i + 1)
                                .map(page => <><Page width={size.width ?? undefined} pageNumber={page} /></>)}


                        </Document>)}

                </SizeMe>


            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={() => onClose()}>Fermer</Button>
                <Button variant="secondary" onClick={() => downloadCV()}>Télécharger</Button>
            </Modal.Footer>

        </Modal>



    </>);
}