import { useEffect, useState } from "react";
import { Card, Modal, Offcanvas, Pagination, Spinner } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAuthDataContext } from "../../core/AuthDataProvider";
import { AdminCandidateResponseModel, CandidateClient, FirstName, IAdminCandidateResponseModel, IAdminValidateCandidate, AdminValidateCandidate, IDeleteCandidateCommand, DeleteCandidateCommand, AdminCandidateClient, WorkExperienceEnum, AdminCandidateSearchStatus, ReferentialData } from "../../services";
import { Candidate } from "./candidate";
import { DeleteCandidateConfirm } from "./deleteCandidateConfirm";
import { FiltersCandidate } from "./filtersCandidate";
import { ShowCVCandidate } from "./showcv";
import { UpdateInfoCandidate } from "./updateInfoCandidate";
import CandidateInfosModal from "../../components/candidateInfosModal/CandidateInfosModal";
import { useReferentielDataContext } from "../../core/ReferentielProvider";

import { TodoAdminModal } from "../../components/todoAdminModal/TodoAdminModal";
import { UploadCV } from "./uploadCV";


export type candidateModal = {
  candidate: AdminCandidateResponseModel | null,
  modalType: "timeLine" | "CV" | "task" | "uploadCV" | null
}

export interface IAdminRequestCommand {
  firstName?: string;
  lastName?: string;
  email?: string;
  startDate?: Date;
  endDate?: Date;
  isValidate?: boolean;
  isPublish?: boolean;
  sortBy?: string,
  sortAsc?: boolean,
  CandidateSearchStatus?: AdminCandidateSearchStatus
  dateField?: string
  workExperience?: WorkExperienceEnum
  source?: string
  haveTimeLine?: boolean;
  haveCV?: boolean;
  excludeFormation?: boolean;
  haveTodo?: boolean;
  schoolLevel?: string
  jobType?: string
  competencies?: ReferentialData[]
  region?: string
  contractType?: string
  school?: string
}

export interface ICandidateFiltersProps {
  onChangeFilters: (command: IAdminRequestCommand) => void;
  searching: boolean;
}
const pagingsize = 20;
const endMessage = (
  <h4 className="text-center">Fin de la liste</h4>
)
const loading_data = (<>
  <h4 className="text-center">Chargement des données ...</h4>
</>)


export function AdminCandidates() {
  var { loading } = useAuthDataContext();

  var _client = new AdminCandidateClient();
  const [show, setShow] = useState<boolean>(false)
  const [searching, setSearching] = useState<boolean>(true)
  const [selectedCandidate, setSelectedCandidate] = useState<IAdminCandidateResponseModel | undefined>()
  const [listCandidates, setListCandidates] = useState<AdminCandidateResponseModel[]>([])
  const [countElement, setCountElement] = useState<number>(0)
  const [search, setSearch] = useState<IAdminRequestCommand>({})
  const [deleteCandidate, setDeleteCandidate] = useState<boolean>(false)
  const [count, setCount] = useState({
    prev: 0,
    next: pagingsize
  })
  const [hasMore, setHasMore] = useState(true);
  //Chargement du référentiel
  const { contractsType, regions, salaryOptions, schoolsOptions, experiencesOptions, jobTypesOptions } = useReferentielDataContext();

  const [candidateModal, setCandidateModal] = useState<candidateModal>({ candidate: null, modalType: null })

  const showCV = candidateModal.modalType === "CV";
  const showTimeLine = candidateModal.modalType === "timeLine";
  const showTask = candidateModal.modalType === "task";
  const showUpload = candidateModal.modalType === "uploadCV";

  useEffect(() => {
    setCount({
      prev: 0,
      next: pagingsize
    })
    var get = async () => await fetchCandidates(0, 20);
    get();
  }, [search, search.firstName])

  const fetchCandidates = async (skip: number, take: number) => {
    setSearching(true);
    const competencies = search.competencies?.map(x => x.label)
    var res = await _client.getAdminCandidates(search.lastName, search.firstName, search.email, search.dateField, search.startDate, search.endDate, search.isValidate, search.isPublish, search.CandidateSearchStatus, search.workExperience, search.source, search.haveTimeLine, search.haveCV, search.excludeFormation, search.haveTodo, search.schoolLevel, search.jobType, competencies, search.region, search.contractType, search.school, skip, take, search.sortBy, search.sortAsc);
    setSearching(false);

    if (skip > 0) {
      setListCandidates(data => [...data].concat(res.data ?? []))
    } else {
      setListCandidates(res.data ?? [])
    }
    setCountElement(res.count ?? 0)
  };

  const changePage = async (skip: number, take: number) => {
    setCount((prevState) => ({ prev: skip, next: skip + pagingsize }))
    await fetchCandidates(count.prev, pagingsize);
  }

  const ShowCv = () => {

  }
  const onChangeFilters = async (command: IAdminRequestCommand) => {
    console.debug(command)
    setCount({
      prev: 0,
      next: pagingsize
    })
    setSearch({ ...command })
  }


  const onSelectCandidate = (selected: IAdminCandidateResponseModel) => {
    setShow(true);
    setSelectedCandidate(selected);
  }

  const getMoreData = async () => {

    setHasMore(count.next < countElement);
    await fetchCandidates(count.next, pagingsize);
    // }
    setTimeout(() => {

    }, 500)
    setCount((prevState) => ({ prev: prevState.prev + pagingsize, next: prevState.next + pagingsize }))
  }
  const updateCandidate = (candidate: AdminCandidateResponseModel) => {
    var cdates = [...listCandidates];
    var idx = cdates.findIndex(d => d.candidateId == candidate.candidateId)
    cdates[idx] = candidate;
    setListCandidates(cdates);
  }

  const onValidateCandidate = async (candidateId: string, candidate: IAdminValidateCandidate) => {
    if (candidateId) {
      var res = await _client.validateCandidateByAdmin(candidateId, AdminValidateCandidate.fromJS(candidate))
      updateCandidate(res);
    }
  }

  const onUploadcv = async (candidate: AdminCandidateResponseModel) => {
    if (candidate) {

      updateCandidate(candidate);

    }
    setCandidateModal({ ...candidateModal, modalType: null })
  }
  const onConfirmDelete = async (selected: IAdminCandidateResponseModel, command: IDeleteCandidateCommand) => {
    if (selected.candidateId) {
      await _client.adminDeleteCandidateById(selected.candidateId, DeleteCandidateCommand.fromJS(command));
      await fetchCandidates(count.prev, pagingsize);
    }
  }

  return (
    <>
      <Card className="mb-2 mt-2">
        <Card.Body>
          <Card.Title>
            Filtre des candidats
          </Card.Title>
          <FiltersCandidate searching={searching} onChangeFilters={onChangeFilters}></FiltersCandidate>
        </Card.Body>
      </Card>
      <Card className="mb-2 mt-2">
        <Card.Body>
          <Card.Title>
            Résultats  {listCandidates.length} / {countElement}
          </Card.Title>

          {searching && <div className="d-flex justify-content-center "><div className="text-center"><div className="text-center mb-2"> Chargement en cours </div><Spinner className="text-center" animation="border" role="status">
            <span className="visually-hidden">Recherche en cours...</span>
          </Spinner></div></div>
          }

          <InfiniteScroll
            dataLength={listCandidates.length}
            next={getMoreData}
            className="candidates-listing-item"
            hasMore={hasMore}
            loader={loading_data}
            endMessage={endMessage}
          >

            {listCandidates.map((c, i) => (<><Candidate setCandidateModal={setCandidateModal} onDelete={() => { setDeleteCandidate(true); setSelectedCandidate(c) }} selected={c.candidateId == selectedCandidate?.candidateId} onSelect={() => onSelectCandidate(c)} candidate={c} key={i} /></>))}
          </InfiniteScroll>
          <div className="d-flex justify-content-center mt-2">

          </div>
        </Card.Body>
      </Card>

      <Modal className="candidate-update-modal" show={show} onHide={() => { setShow(false); setSelectedCandidate(undefined); }} placement="end" size="xl">
        <Modal.Header className="bg-primary  text-white" closeButton>
          <Modal.Title>Mettre à jour le candidat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Modal.Title className="bg-secondary text-center"><b className="me-2">{selectedCandidate?.lastName}</b>{selectedCandidate?.firstName}</Modal.Title>
        </Modal.Body>

        <UpdateInfoCandidate referential={{ jobTypesOptions, experiencesOptions, schoolsOptions, regions, salaryOptions, contractsType }} candidate={selectedCandidate} update={(model) => onValidateCandidate(selectedCandidate?.candidateId ?? "", model)} cancel={() => { setShow(false); setSelectedCandidate(undefined); }} ></UpdateInfoCandidate>

      </Modal>

      {
        selectedCandidate && deleteCandidate && <> <DeleteCandidateConfirm candidate={selectedCandidate}
          cancel={() => { setDeleteCandidate(false); setSelectedCandidate(undefined); }}
          confirm={(command) => { onConfirmDelete(selectedCandidate, command); setDeleteCandidate(false); setSelectedCandidate(undefined); }} />

        </>
      }
      {showCV && <ShowCVCandidate onClose={() => setCandidateModal({ ...candidateModal, modalType: null })} candidateId={candidateModal.candidate?.candidateId ?? ""}></ShowCVCandidate>}

      {showTimeLine && candidateModal.candidate && <CandidateInfosModal hide={() => setCandidateModal({ ...candidateModal, modalType: null })} candidate={candidateModal.candidate} />}
      {showTask && candidateModal.candidate && <TodoAdminModal onUpdate={updateCandidate} hide={() => setCandidateModal({ ...candidateModal, modalType: null })} candidate={candidateModal.candidate} />}

      {showUpload && candidateModal.candidate && <UploadCV onValidate={onUploadcv} candidateId={(candidateModal.candidate?.candidateId ?? "")} onClose={() => setCandidateModal({ ...candidateModal, modalType: null })}></UploadCV>}
    </>)
}