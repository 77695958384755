
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AdminCandidateClient, AdminCandidateResponseModel, AdminClient } from "../../services"

export interface IUploadCVProps {
    candidateId: string
    onClose: () => void;
    onValidate: (candidate: AdminCandidateResponseModel) => void;
}

export const UploadCV = ({ candidateId, onClose, onValidate }: IUploadCVProps) => {
    var client = new AdminCandidateClient();


    const [fileData, setFileData] = useState()
    const [fileName, setFileName] = useState<string>("")
    const changeFile = (e: any) => {

        setFileData(e.target.files[0]);
        setFileName(e.target.files[0].name);
    }
    const uploadFile = async () => {
        var res = await client.uploadCVCandidat(candidateId, { "data": fileData, "fileName": fileName })
        onValidate(res);
    }
    return (<>
        <Modal show={true} size="lg" onHide={() => onClose()}>
            <Modal.Header closeButton>
                <Modal.Title>Mise à jour du CV Candidat</Modal.Title>
            </Modal.Header>

            <Modal.Body className="">
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Upload du CV</Form.Label>
                    <Form.Control onChange={changeFile} type="file" />
                </Form.Group>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary">Annuler</Button>
                <Button variant="primary" onClick={() => uploadFile()}>Mettre à jour</Button>
            </Modal.Footer>
        </Modal >

    </>)

}

function FileParameter(candidateId: string, FileParameter: any, fileData: undefined) {
    throw new Error("Function not implemented.");
}
