import { FieldError, FieldErrors, Merge } from "react-hook-form"

export const errorClass = (field?: Merge<FieldError, FieldError[]>, className?: string) => ({ className: `${field ? "error" : ""} ${className ? className : ""}` })

export const renderErrors = (errors: FieldErrors) => {
    const line = (message: string) => <li key={message}>{message}</li>

    const handleErrors = (err: any): any => {
        if (err.message !== undefined) {
            return line(err.message)
        }
        else {
            return Object.keys(err).map((key: any) => {
                return handleErrors(err[key])
            })
        }
    }

    if (!errors || Object.keys(errors).length === 0) return <></>

    console.log('errors', errors)

    return (
        <ul style={{ color: "red", paddingTop: "1rem", listStyle: "inside" }}>
            {handleErrors(errors)}
        </ul>
    )
}