import { useEffect, useState } from "react";
import { Badge, Button, ButtonGroup, Card, Col, Container, Dropdown, FloatingLabel, Form, Row } from "react-bootstrap";


import { IAdminRequestCommand, ICandidateFiltersProps } from ".";
import { DateTime } from "luxon";
import { faArrowTrendDown, faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AdminCandidateSearchStatus, WorkExperienceEnum } from "../../services";
import { useReferentielDataContext } from "../../core/ReferentielProvider";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useSkills } from "../../hooks/useSkills";

const initialState: IAdminRequestCommand = {
    sortAsc: false,
    sortBy: "updateDate"
}

type FilterSourceModel = {
    key: string
    name: string
}

export function FiltersCandidate({ onChangeFilters, searching }: ICandidateFiltersProps) {
    const [search, setSearch] = useState<IAdminRequestCommand>(initialState)
    const [sources, setSources] = useState<FilterSourceModel[]>([])

    const {
        experiencesOptions,
        eventsOptions,
        schoolLevels,
        jobTypesOptions,
        regions,
        schoolsOptions,
        contractsTypeWithoutAvailability
    } = useReferentielDataContext();

    const skills = useSkills()

    useEffect(() => {
        const defaultSource = {
            key: "local",
            name: "Cyberjobs interne"
        }

        const sources = new Array<FilterSourceModel>(defaultSource)

        if (eventsOptions) {
            sources.push(...eventsOptions
                .map(x => ({ key: `event_${x.value}`, name: x.label! })))
        }

        setSources(sources)

        return () => setSources([defaultSource])
    }, [eventsOptions])

    const onSearchChange = (field: string, value: string) => {
        setSearch(s => ({ ...s, [field]: value }))
    }

    const updateDatesSearch = (startDate: Date | undefined, endDate: Date | undefined) => {
        setSearch(s => ({ ...s, ["startDate"]: startDate, ["endDate"]: endDate }));
    }
    const onClearSearch = () => {
        setSearch(initialState);
        onChangeFilters(initialState);
    }


    const handleValidate = (field: string, value: boolean | undefined) => {
        var actual_value = (search as any)[field];
        if (actual_value !== value) {
            setSearch(s => ({ ...s, [field]: value }));
            return;
        }

    }

    return (<>
        <Container fluid>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Row>
                    <Col className="mt-1" md="6" xl="4">
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Nom">
                            <Form.Control type="text" placeholder="Nom" value={search.lastName ?? ''} onChange={(e) => onSearchChange("lastName", e.target.value)} />
                        </FloatingLabel>
                    </Col>
                    <Col className="mt-1" md="6" xl="4">
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Prénom">
                            <Form.Control type="text" placeholder="Prénom" value={search.firstName ?? ''} onChange={(e) => onSearchChange("firstName", e.target.value)} />
                        </FloatingLabel>
                    </Col>
                    <Col className="mt-1" md="6" xl="4">
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Email">
                            <Form.Control type="text" placeholder="Email" value={search.email ?? ''} onChange={(e) => onSearchChange("email", e.target.value)} />
                        </FloatingLabel>
                    </Col>
                </Row>
            </Form.Group>
            <Row>
                <Col md="4">
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Statut Candidat"
                        className="mb-3"
                    >
                        <Form.Control
                            className="mt-2"
                            required
                            value={search?.CandidateSearchStatus ?? AdminCandidateSearchStatus.Undefined}
                            as="select"
                            onChange={(e) => setSearch(s => ({
                                ...s, CandidateSearchStatus: e.target.value ? (e.target.value as unknown as AdminCandidateSearchStatus) : undefined
                            }))}
                        >
                            <option value={AdminCandidateSearchStatus.Undefined}>Sélectionner un statut</option>
                            <option value={AdminCandidateSearchStatus.SimpleUser}>Utilisateur</option>
                            <option value={AdminCandidateSearchStatus.ActiveSearchJob}>Rech. emploi Active</option>
                            <option value={AdminCandidateSearchStatus.PassiveSearchJob}>Rech. emploi Passive</option>
                            <option value={AdminCandidateSearchStatus.Freelance}>Freelance</option>
                            <option value={AdminCandidateSearchStatus.BasicTraining}>Rech. formation initiale</option>
                            <option value={AdminCandidateSearchStatus.ContinuingEducation}>Rech. formation continue</option>
                        </Form.Control>

                    </FloatingLabel>
                </Col>

                <Col md="4">
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Expérience Cyber Candidat"
                        className="mb-3"
                    >
                        <Form.Control
                            className="mt-2"
                            required
                            value={search?.workExperience ?? ''}
                            as="select"
                            onChange={(e) => setSearch(s => ({
                                ...s, ["workExperience"]: e.target.value ? (e.target.value as unknown as WorkExperienceEnum) : undefined
                            }))}
                        >
                            <option value={""}>Sélectionner un niveau d'XP</option>
                            {experiencesOptions.map(e => (
                                <option key={e.value} value={e.value}>{e.label}</option>)
                            )}
                        </Form.Control>
                    </FloatingLabel>
                </Col>
                <Col md="4">
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Source du candidat"
                        className="mb-3"
                    >
                        <Form.Control
                            className="mt-2"
                            required
                            value={search?.source ?? ''}
                            as="select"
                            onChange={(e) => setSearch(s => ({
                                ...s, ["source"]: e.target.value
                            }))}
                        >
                            <option value={""}>Sélectionner une source</option>

                            {sources.map(e => (
                                <option key={e.key} value={e.key}>{e.name}</option>)
                            )}
                        </Form.Control>

                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Niveau d'études"
                        className="mb-3"
                    >
                        <Form.Control
                            className="mt-2"
                            required
                            value={search?.schoolLevel ?? ''}
                            as="select"
                            onChange={(e) => setSearch(s => ({
                                ...s, ["schoolLevel"]: e.target.value
                            }))}
                        >
                            <option value={""}>Sélectionner un niveau d'étude</option>

                            {schoolLevels.map(e => (
                                <option key={e.value} value={e.value}>{e.label}</option>)
                            )}
                        </Form.Control>

                    </FloatingLabel>
                </Col>
                <Col md="4">
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Type de contrat"
                        className="mb-3"
                    >
                        <Form.Control
                            className="mt-2"
                            required
                            value={search?.contractType ?? ''}
                            as="select"
                            onChange={(e) => setSearch(s => ({
                                ...s, ["contractType"]: e.target.value
                            }))}
                        >
                            <option value={""}>Sélectionner un type de contrat</option>

                            {contractsTypeWithoutAvailability.map(e => (
                                <option key={e.value} value={e.value}>{e.label}</option>)
                            )}
                        </Form.Control>
                    </FloatingLabel>
                </Col>
                <Col md="4">
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Métier"
                        className="mb-3"
                    >
                        <Form.Control
                            className="mt-2"
                            required
                            value={search?.jobType ?? ''}
                            as="select"
                            onChange={(e) => setSearch(s => ({
                                ...s, ["jobType"]: e.target.value
                            }))}
                        >
                            <option value={""}>Sélectionner un métier</option>

                            {jobTypesOptions.map((opt, i) => {
                                if (opt.label) {
                                    return (
                                        <optgroup key={i} label={opt.label}>
                                            {opt.options!.map((child, j) => <option key={j} value={child.value}>{child.label}</option>)}
                                        </optgroup>
                                    )
                                }
                                else {
                                    return opt.options!.map((child, j) => <option key={j} value={child.value}>{child.label}</option>)
                                }
                            })}
                        </Form.Control>

                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Ecole"
                        className="mb-3"
                    >
                        <Form.Control
                            className="mt-2"
                            required
                            value={search?.school ?? ''}
                            as="select"
                            onChange={(e) => setSearch(s => ({
                                ...s, ["school"]: e.target.value
                            }))}
                        >
                            <option value={""}>Sélectionner une école</option>

                            {schoolsOptions.map(e => (
                                <option key={e.value} value={e.value}>{e.label}</option>)
                            )}
                        </Form.Control>

                    </FloatingLabel>
                </Col>                <Col md="4">
                    <AsyncTypeahead
                        filterBy={() => true}
                        onChange={(e: any[]) => setSearch(s => ({ ...s, competencies: e }))}
                        isLoading={skills.loading}
                        labelKey="label"
                        multiple
                        clearButton
                        allowNew={false}
                        className="skills-filter w-100"
                        selected={search.competencies ?? []}
                        onSearch={skills.search}
                        options={skills.results}
                        placeholder="Compétences"
                    >
                    </AsyncTypeahead>
                </Col>
                <Col md="4">
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Région actuelle"
                        className="mb-3"
                    >
                        <Form.Control
                            className="mt-2"
                            required
                            value={search?.region ?? ''}
                            as="select"
                            onChange={(e) => setSearch(s => ({
                                ...s, ["region"]: e.target.value
                            }))}
                        >
                            <option value={""}>Sélectionner une région</option>

                            {regions.map((opt, i) => {
                                if (opt.regions && opt.regions.length > 0) {
                                    return (
                                        <optgroup key={i} label={opt.label}>
                                            {opt.regions!.map((child, j) => <option key={j} value={child.label}>{child.label}</option>)}
                                        </optgroup>
                                    )
                                }
                                else {
                                    return (
                                        <optgroup key={i} label={opt.label}>
                                            <option key={opt.label} value={opt.label}>{opt.label}</option>
                                        </optgroup>
                                    )
                                }
                            })}
                        </Form.Control>

                    </FloatingLabel>
                </Col>
            </Row>
            <Form.Group>
                <Row>

                    <Col xs={12} md={6}>
                        <div>


                            <Form.Check
                                type="checkbox"
                                label="Tous"
                                className="d-inline-block mx-1"

                                checked={search.isValidate === undefined}
                                id={`tous`}
                                onChange={(e) => {
                                    handleValidate("isValidate", undefined);
                                }}></Form.Check>
                            <Form.Check
                                type="checkbox"
                                label="Non Validé"
                                className="d-inline-block mx-1"
                                checked={search.isValidate === false}
                                id={`nonvalide`}
                                onChange={(e) => {
                                    handleValidate("isValidate", false);
                                    setSearch(s => ({ ...s, ["isPublish"]: undefined }));

                                    //  updateDatesSearch(DateTime.now().startOf("week").plus({ days: -60 }).toJSDate(), DateTime.now().toJSDate())
                                }}></Form.Check>

                            <Form.Check
                                type="checkbox"
                                label="Validé"
                                className="d-inline-block mx-1"
                                checked={search.isValidate === true}
                                id={`estvalide`}
                                onChange={(e) => {

                                    handleValidate("isValidate", true);

                                    // updateDatesSearch(DateTime.now().startOf("week").plus({ days: -60 }).toJSDate(), DateTime.now().toJSDate())
                                }}></Form.Check>
                            {search.isValidate != false && <> (  <Form.Check
                                className="d-inline-block mx-1"
                                type="checkbox"
                                label="Tous"
                                checked={search.isPublish === undefined}
                                id={`estpublie`}
                                onChange={(e) => handleValidate("isPublish", undefined)} />
                                <Form.Check
                                    className="d-inline-block mx-1"
                                    type="checkbox"
                                    label="Publié"
                                    checked={search.isPublish === true}
                                    id={`esttouspublie`}
                                    onChange={(e) => handleValidate("isPublish", true)} />

                                <Form.Check
                                    className="d-inline-block mx-1"
                                    type="checkbox"
                                    label="Non Publié"
                                    checked={search.isPublish === false}
                                    id={`nonpublie`}
                                    onChange={(e) => handleValidate("isPublish", false)} />
                                )</>
                            }
                        </div>
                    </Col>
                    <Col md="2">
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Exclure les form. uniq."
                            className="mb-3"
                        >
                            <Form.Control
                                className="mt-2"
                                required
                                value={search?.excludeFormation ? "Oui" : search?.excludeFormation === false ? "Non" : ""}
                                as="select"
                                onChange={(e) => setSearch(s => ({
                                    ...s, ["excludeFormation"]: (e.target.value === "Oui" ? true : e.target.value === "Non" ? false : undefined)
                                }))}
                            >
                                <option value={""}>Indifférent</option>
                                <option value={"Non"}>Non</option>
                                <option value={"Oui"}>Oui</option>


                            </Form.Control>
                        </FloatingLabel>

                    </Col>
                    <Col md="2">
                        <FloatingLabel
                            controlId="floatingInput"
                            label="CV"
                            className="mb-3"
                        >
                            <Form.Control
                                className="mt-2"
                                required
                                value={search?.haveCV ? "Oui" : search?.haveCV === false ? "Non" : ""}
                                as="select"
                                onChange={(e) => setSearch(s => ({
                                    ...s, ["haveCV"]: (e.target.value === "Oui" ? true : e.target.value === "Non" ? false : undefined)
                                }))}
                            >
                                <option value={""}>Indifférent</option>
                                <option value={"Non"}>Non</option>
                                <option value={"Oui"}>Oui</option>


                            </Form.Control>
                        </FloatingLabel>

                    </Col>

                    <Col md="2">

                        <FloatingLabel
                            controlId="floatingInput"
                            label="Timeline validée"
                            className="mb-3"
                        >
                            <Form.Control
                                className="mt-2"
                                required
                                value={search?.haveTimeLine ? "Oui" : search?.haveTimeLine === false ? "Non" : ""}
                                as="select"
                                onChange={(e) => setSearch(s => ({
                                    ...s, ["haveTimeLine"]: (e.target.value === "Oui" ? true : e.target.value === "Non" ? false : undefined)
                                }))}
                            >
                                <option value={""}>Indifférent</option>
                                <option value={"Non"}>Non</option>
                                <option value={"Oui"}>Oui</option>


                            </Form.Control>
                        </FloatingLabel>
                    </Col>


                </Row>
                <Row className="mt-2">

                    <Col>

                        {<ButtonGroup aria-label="Basic example" className="w-100 predefined-filter"><b>Dates&nbsp;prédéfinies&nbsp;:</b>
                            <a onClick={() => updateDatesSearch(DateTime.now().startOf("day").toJSDate(), undefined)} className="mx-1">
                                Aujourd'hui
                            </a>

                            <a onClick={() => updateDatesSearch(DateTime.now().startOf("week").toJSDate(), DateTime.now().toJSDate())} className="mx-1">
                                Cette Semaine
                            </a>
                            <a onClick={() => updateDatesSearch(DateTime.now().startOf("month").toJSDate(), DateTime.now().toJSDate())} className="mx-1">
                                Mois en cours
                            </a>
                            <a onClick={() => updateDatesSearch(DateTime.now().plus({ days: -120 }).toJSDate(), DateTime.now().toJSDate())} className="mx-1">
                                CVThèque(120 jours)
                            </a>
                            <a onClick={() => updateDatesSearch(undefined, DateTime.now().plus({ days: -121 }).toJSDate())} className="mx-1">
                                Communauté (+ 120 jours)
                            </a>
                        </ButtonGroup>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md="4" xl="3">
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Filtrer sur"
                            className="mb-3"
                        >
                            <Form.Control
                                className="mt-2"
                                required
                                value={search?.dateField}
                                as="select"
                                onChange={(e) => setSearch(s => ({
                                    ...s, ["dateField"]: e.target.value
                                }))}
                            >
                                <option value="updateDate">Mise à jour entre</option>
                                <option value="ValidatedDate">Validé entre</option>
                                <option value="PublishedDate">Publié entre</option>
                            </Form.Control>

                        </FloatingLabel>
                    </Col>
                    <Col md="4" xl="3">

                        <FloatingLabel
                            controlId="floatingInput"
                            label="Date début "
                            className="mb-3"
                        >

                            <Form.Control
                                className="mt-2"
                                required
                                value={search.startDate ? DateTime.fromJSDate(search.startDate).toFormat("yyyy-MM-dd") : ""}
                                type="date"
                                onChange={(e) => {

                                    setSearch(s => ({ ...s, ["startDate"]: DateTime.fromISO(e.target.value).toLocal().toJSDate() }))
                                }}
                            >
                            </Form.Control>
                        </FloatingLabel>
                    </Col>
                    <Col md="4" xl="3">
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Date fin "
                            className="mb-3"
                        >
                            <Form.Control
                                className="mt-2"
                                required
                                value={search.endDate ? DateTime.fromJSDate(search.endDate).toFormat("yyyy-MM-dd") : ""}
                                type="date"
                                onChange={(e) => {
                                    console.log(e.target.value);

                                    setSearch(s => ({

                                        ...s, ["endDate"]: DateTime.fromISO(e.target.value).toLocal().toJSDate()
                                    }))

                                }}
                            >
                            </Form.Control>

                        </FloatingLabel>


                    </Col>

                    <Col>
                        <Form.Check
                            className="d-inline-block mx-1"
                            type="checkbox"
                            label="Actions à faire ou en cours"
                            checked={search.haveTodo === true}
                            id={`haveTodo`}
                            onChange={(e) => handleValidate("haveTodo", e.target.checked)} />
                    </Col>
                </Row>
                <Row>
                    <Form.Label>Trier par</Form.Label>
                    <Col md="12" className="w-100 ">

                        <ButtonGroup aria-label="Statut du CV" className="mt-1 d-inline">
                            <Button variant={(search?.sortBy == "updateDate" ? "success" : "outline-primary")} onClick={() => setSearch(s => ({ ...s, ["sortBy"]: "updateDate" }))} className="mx-1">Date mise à jour</Button>
                        </ButtonGroup>

                        <ButtonGroup aria-label="Statut du CV" className="w-100 mt-1 d-inline">
                            <Button disabled={searching} variant={(search?.sortAsc === true ? "success" : "outline-primary")} onClick={() => {
                                setSearch(s => ({ ...s, ["sortAsc"]: true }));
                                onChangeFilters(search)
                            }
                            } className="mx-1">     <FontAwesomeIcon className="mx-1" icon={faArrowTrendUp} />Croissant</Button>
                            <Button disabled={searching} variant={(search?.sortAsc === false ? "success" : "outline-primary")} onClick={() => {
                                setSearch(s => ({ ...s, ["sortAsc"]: false }));
                                onChangeFilters(search)
                            }
                            } className="mx-1"><FontAwesomeIcon className="mx-1" icon={faArrowTrendDown} />Descendant</Button>
                        </ButtonGroup>

                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col>
                        <div className="float-end">
                            <Button variant="outline-disabled" className="mx-1" onClick={(e) => onClearSearch()}>Réinitialiser les filtres</Button>
                            <Button disabled={searching} variant="outline-primary" onClick={(e) => onChangeFilters(search)}>Rechercher</Button>
                        </div>
                    </Col>
                </Row>
            </Form.Group>
        </Container>

    </>)


}