import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/navigation/Footer";
import { SideBar } from "../components/navigation/SideBar";
import { SupportNavBar } from "../components/navigation/SupportNavBar";
import { useAuthDataContext } from "../core/AuthDataProvider";


const AdminLayout = ({ children }: any) => {
    const { isAuthenticated, loading } = useAuthDataContext();
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        console.log("isAutenticated");
        console.log(isAuthenticated, loading)
        if (!isAuthenticated && !loading) {
            console.log(isAuthenticated, loading)
            navigate(`/login?redirectUrl=${location.pathname}`);
        }
        return () => {

        }
    }, [isAuthenticated, loading])

    if (loading) return <></>;
    return (
        <>
            <SupportNavBar />
            <Container fluid>
                <Row>
                    <div className="col-md-3 col-lg-3 col-xl-2  col-xxl-1">
                        <SideBar></SideBar>
                    </div>
                    <main className="col-md-9 ml-sm-auto col-lg-9  col-xxl-11 col-xl-10 px-md-4 py-4">
                        {children}
                    </main>
                </Row>

            </Container>
            <footer>



                <Footer></Footer>
            </footer>
        </>)
}

export default AdminLayout;