import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { Button, Card } from "react-bootstrap"
import { useImage } from "../../hooks/useImage"
import { AdminOrganizationListModel } from "../../services"

type OrganizationCardProps = {
    organization: AdminOrganizationListModel
}

export const OrganizationCard = ({ organization }: OrganizationCardProps) => {
    const image = useImage()
    const [summary, setSummary] = useState("")

    useEffect(() => {
        if (organization?.presentations !== undefined && organization.presentations.length > 0) {
            const presentation = organization.presentations.find(x => x.language === "fr-FR") ?? organization.presentations[0]
            setSummary(presentation.presentationSummary!)
        }
        else {
            setSummary("")
        }
    }, [organization?.presentations])

    return (
        <Card className="organization-list-item">
            <Card.Title>
                <div className="organization-list-item-image">
                    {organization.backgroundImage && <img src={image(organization.backgroundImage)} />}
                    <div className="organization-list-item-image-logo">
                        {organization.logo && <img src={image(organization.logo)} />}
                    </div>
                </div>
                <PublishIcon organization={organization} />
            </Card.Title>
            <Card.Body>
                <div className="organization-list-item-title">{organization.name}</div>
                <div className="organization-list-item-summary">{summary}</div>
            </Card.Body>
            {/* <Card.Footer>
                    <Button variant="outline-primary">Voir la fiche</Button>
                </Card.Footer> */}
        </Card>
    )
}

type PublishIconProps = {
    organization: AdminOrganizationListModel
}

const PublishIcon = ({ organization }: PublishIconProps) => {

    const [icon, setIcon] = useState<IconDefinition>(faEyeSlash)
    const [isActive, setActive] = useState(false)

    useEffect(() => {
        setIcon(organization?.active ? faEye : faEyeSlash)
        setActive(organization?.active ?? false)
    }, [organization?.active])

    return (
        <div className={`organization-list-item-header-icon ${isActive ? 'active' : ''}`}>
            <FontAwesomeIcon icon={icon} size='lg' />
        </div>
    )
}