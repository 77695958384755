import React from "react";
import { Container, Row } from "react-bootstrap";
import Footer from "../components/navigation/Footer";
import { SideBar } from "../components/navigation/SideBar";
import { SupportNavBar } from "../components/navigation/SupportNavBar";


const AdminLayout = ({ children }: any) => {
    return (
        <>

            <Container className="flex-1" fluid>
                <Row>
                    <main className="col-md-12">
                        <div className="text-center">
                            {children}
                        </div>
                    </main>
                </Row>
            </Container>
            <footer>
                <Footer></Footer>
            </footer>
        </>)
}

export default AdminLayout;