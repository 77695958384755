import { ReferentielContextType } from "../../core/ReferentielProvider";
import { IReferentialData, JobSalaryModel, ReferentialClient, ReferentialData } from "../../services";
import { SkillsResult, useSkills } from "../../hooks/useSkills";

const useDegrees = (): IReferentialData[] => {
    return [
        {
            label: 'Pas de diplôme requis',
            value: ''
        },
        {
            label: 'Bac',
            value: 'Bac'
        },
        {
            label: 'Bac +2',
            value: 'Bac +2'
        },
        {
            label: 'Bac +3',
            value: 'Bac +3'
        },
        {
            label: 'Bac +4',
            value: 'Bac +4'
        },
        {
            label: 'Bac +5',
            value: 'Bac +5'
        },
        {
            label: 'Master',
            value: 'Master'
        },
        {
            label: 'Doctorat',
            value: 'Doctorat'
        }
    ]
}

const useExperiences = (): IReferentialData[] => {
    return [
        {
            label: 'Aucune expérience',
            value: 'Aucune'
        },
        {
            label: 'Entre 1 à 2 ans',
            value: 'Entre 1 à 2 ans'
        },
        {
            label: 'De 3 à 5 ans',
            value: 'De 3 à 5 ans'
        },
        {
            label: 'De 6 à 10 ans',
            value: 'De 6 à 10 ans'
        },
        {
            label: 'Plus de 10 ans',
            value: '10 ans'
        }
    ]
}

/**
 * Retourne le salaire à partir du référentiel
 * @param referential 
 * @param salary 
 * @returns 
 */
const getSalary = (referential: ReferentielContextType, salary: JobSalaryModel | undefined) => {
    if (salary?.minValue) {
        const item = referential.jobsSalary.find(x => {
            const values = x.value!.split('-')
            if (values?.length > 1) {
                const minValue = parseInt(values[0])
                const maxValue = parseInt(values[1])

                return salary.minValue! >= minValue && salary.minValue! <= maxValue
            }
            return false
        })

        if (item) {
            return item.value
        }
    }

    return ""
}

export type EditableJobOptions = {
    degrees: IReferentialData[]
    experiences: IReferentialData[]
    getSalary: (referential: ReferentielContextType, salary: JobSalaryModel | undefined) => string | undefined
    skills: SkillsResult
}

export const useOptions = (): EditableJobOptions => {
    return {
        degrees: useDegrees(),
        experiences: useExperiences(),
        getSalary: getSalary,
        skills: useSkills()
    }
}