import { faHandHolding, faHandshake, faHome, faList, faUsers, faLink, faSitemap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { ListGroup, Nav, Offcanvas } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";




export function SideBar() {
  const [show, setshow] = useState(true)
  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  return (
    <>

      <nav id="sidebar" className="d-md-block bg-light sidebar collapse">
        <div className="position-sticky">
          <ul className="nav flex-column">
            <li className="nav-item">
              <Link to="/" className={"nav-link " + (splitLocation[1] === "" ? "active" : "")} >
                <FontAwesomeIcon className="me-1" icon={faHome} />
                <span className="ms-2">Tableau de bord</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/impersonation" className={"nav-link " + (splitLocation[1] === "impersonation" ? "active" : "")} >
                <FontAwesomeIcon className="me-1" icon={faLink} />
                <span className="ms-2">Connexion</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/adminCandidates" className={"nav-link " + (splitLocation[1] === "adminCandidates" ? "active" : "")} >
                <FontAwesomeIcon className="me-1" icon={faUsers} />
                <span className="ms-2">Admin Candidats</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/adminJobs" className={"nav-link " + (splitLocation[1] === "adminJobs" ? "active" : "")} >
                <FontAwesomeIcon className="me-1" icon={faList} />
                <span className="ms-2">Admin Jobs</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/adminOrganizations" className={"nav-link " + (splitLocation[1] === "adminOrganizations" ? "active" : "")} >
                <FontAwesomeIcon className="me-1" icon={faSitemap} />
                <span className="ms-2">Organisations</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/adminEvents" className={"nav-link " + (splitLocation[1] === "adminEvents" ? "active" : "")} >
                <FontAwesomeIcon className="me-1" icon={faHandshake} />
                <span className="ms-2">Evénements</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>

    </>
  )
}