import React, { useEffect, useState } from "react";
// react-bootstrap components
import {
    Button, Card, Col, Container, FloatingLabel, Form, Row, Spinner, Table
} from "react-bootstrap";
import { useAuthDataContext } from "../../core/AuthDataProvider";
import { AdminUserClient, EntityEmployeeIdentifierModel, EntityType, SearchEmployeeAccountModel } from "../../services";


export function Impersonation() {

    useAuthDataContext()
    const adminUserClient = new AdminUserClient()
    const [initial, setInitial] = useState(true)
    const [searching, setSearching] = useState(false)
    const [companies, setCompanies] = useState<SearchEmployeeAccountModel[]>([])
    const [schools, setSchools] = useState<SearchEmployeeAccountModel[]>([])
    const [organizations, setOrganizations] = useState<SearchEmployeeAccountModel[]>([])
    const [error, setError] = useState<any | undefined>()

    const onClear = () => {
        setInitial(true)
        setError(undefined)
        setCompanies([])
        setSchools([])
        setOrganizations([])
    }

    const onSearch = async (filter: Filter) => {
        setSearching(true)
        setError(undefined)
        try {
            await searchCompanies(filter)
            await searchSchools(filter)
            await searchOrganizations(filter)

            setInitial(false)
        }
        catch (error) {
            setError(error)
        }
        finally {
            setSearching(false)
        }
    }

    const searchCompanies = async (filter: Filter) => {
        const response = await adminUserClient.searchEmployeesAccounts(EntityType.Company, filter.input)
        setCompanies(response.list ?? [])
    }

    const searchSchools = async (filter: Filter) => {
        const response = await adminUserClient.searchEmployeesAccounts(EntityType.School, filter.input)
        setSchools(response.list ?? [])
    }

    const searchOrganizations = async (filter: Filter) => {
        const response = await adminUserClient.searchEmployeesAccounts(EntityType.Organization, filter.input)
        setOrganizations(response.list ?? [])
    }

    const renderResults = () => {
        if (error) {
            return (
                <Card className="mb-2 mt-2" >
                    <Card.Body style={{ textAlign: "center", color: "red" }}>
                        Une erreur est survenue.<br />Vérifier la validité de l'expression régulière.
                    </Card.Body>
                </Card>
            )
        }

        if (initial) return <></>

        return (
            <>
                <Results label="Entreprises" searching={searching} results={companies} />
                <Results label="Ecoles" searching={searching} results={schools} />
                <Results label="Organisations" searching={searching} results={organizations} />
            </>
        )
    }

    return (
        <>
            <Card className="mb-2 mt-2" >
                <Card.Body>
                    <Card.Title>
                        Connexion à un compte utilisateur
                    </Card.Title>
                    <Filters search={onSearch} clear={onClear} searching={searching} />
                </Card.Body>
            </Card>
            {renderResults()}
        </>
    )
}

type Filter = {
    input: string
}

type FiltersProps = {
    searching: boolean
    search: (filter: Filter) => Promise<void>
    clear: () => void
}

const Filters = ({ searching, search, clear }: FiltersProps) => {
    const [input, setInput] = useState("")
    const [submitDisabled, setSubmitDisabled] = useState(true)

    useEffect(() => {
        setSubmitDisabled(searching || input === "")
    }, [input])

    const onClearSearch = () => {
        setInput("")
        clear()
    }

    const onSubmitSearch = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const filter = {
            input: input
        }
        await search(filter)
    }

    return (
        <Container fluid>
            <Form onSubmit={(e) => onSubmitSearch(e)}>
                <Form.Group className="mb-3">
                    <Row>
                        <Col className="mt-1" md="6" xl="4">
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Recherche (nom d'entreprise/école/employé, adresse email, ...)">
                                <Form.Control type="text" value={input} placeholder="Reherche" onChange={(e) => setInput(e.target.value)} />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <div className="float-end">
                                <Button disabled={submitDisabled} variant="outline-disabled" className="mx-1" onClick={(e) => onClearSearch()}>Réinitialiser les filtres</Button>
                                <Button disabled={submitDisabled} variant="outline-primary" type="submit">Rechercher</Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ fontSize: "13px" }}>
                            Veillez à fermer toutes les fenêtres du site Cyberjobs avant de vous connecter à un compte utilisateur.
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
        </Container>
    )
}

type ResultsProps = {
    label: string
    searching: boolean
    results: SearchEmployeeAccountModel[]
}

const Results = ({ label, searching, results }: ResultsProps) => {

    return (
        <Card className="mb-2 mt-2">
            <Card.Body>
                <Card.Title>
                    {label}{searching === false && ` (${results.length})`}
                </Card.Title>
                {searching &&
                    <div className="d-flex justify-content-center ">
                        <div className="text-center">
                            <div className="text-center mb-2">Chargement en cours</div>
                            <Spinner className="text-center" animation="border" role="status">
                                <span className="visually-hidden">Recherche en cours...</span>
                            </Spinner>
                        </div>
                    </div>
                }
                {!searching &&
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Employé</th>
                                <th>Email</th>
                                <th>Type de compte</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {results.map((result, i) => (
                                <Result key={result.employeeIdentifier?.employeeId} result={result} />
                            ))}
                        </tbody>
                    </Table>
                }
            </Card.Body>
        </Card>
    )
}

type ResultProps = {
    result: SearchEmployeeAccountModel
}

const Result = ({ result }: ResultProps) => {
    const adminUserClient = new AdminUserClient()
    const [loading, setLoading] = useState(false)
    const [link, setLink] = useState<string>()

    const impersonate = async (e: React.MouseEvent<HTMLAnchorElement>, employeeIdentifier: EntityEmployeeIdentifierModel) => {
        e.preventDefault()
        setLoading(true)

        try {
            const link = await adminUserClient.impersonate(employeeIdentifier)
            setLink(link)
        } catch (error) {
            alert('Une erreur est survenue lors de la génération du jeton')
        } finally {
            setLoading(false)
        }
    }

    const renderAction = () => {
        if (loading) return <>...</>

        if (link !== undefined) {
            return (
                <a href={link} target="_blank">Se connecter</a>
            )
        }

        return (
            <a href="#" onClick={(e) => impersonate(e, result.employeeIdentifier!)}>Générer un jeton</a>
        )
    }

    return (
        <tr key={result.employeeIdentifier?.employeeId}>
            <td>{result.entityName}</td>
            <td>{result.employeeName}</td>
            <td>{result.employeeEmail}</td>
            <td>{result.isAdmin ? "Administrateur" : "Utilisateur"}</td>
            <td>{renderAction()}</td>
        </tr>
    )
}