import { useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { FilterJobs } from "./FilterJobs";
import { useAuthDataContext } from "../../core/AuthDataProvider";
import { AdminJobClient, AdminJobModel, CommandError, CommandErrorDetail, GetAdminJobListFilterCommand, IGetAdminJobListFilterCommand, JobStatus, ValidationErrorCode } from "../../services";
import InfiniteScroll from "react-infinite-scroll-component";
import './adminJobs.scss'
import { JobCard } from "./JobCard";
import { GoTop } from "../../components/GoTop/GoTop";
import { EditableJobCard } from "../../components/EditableJobCard";
import { ValidationErrorPanel } from "../../components/ValidationErrorPanel/ValidationErrorPanel";

const PAGING_SIZE = 20

export function AdminJobs() {
    useAuthDataContext();
    const _adminJobClient = new AdminJobClient()
    const [loading, toggleLoading] = useState(true)
    const [currentPageNum, setCurrentPageNum] = useState<number>(0)
    const [hasMore, setHasMore] = useState(true);
    const [jobList, setJobList] = useState<AdminJobModel[]>([])
    const [commandError, setCommandError] = useState<CommandError | null>(null)
    const [total, setTotal] = useState<number>(0)
    const [filter, setFilter] = useState<IGetAdminJobListFilterCommand>({})
    const [editJob, setEditJob] = useState<AdminJobModel | undefined>()
    const [scrollToJob, setScrollToJob] = useState<string | undefined>()

    useEffect(() => {
        refresh()
    }, [filter])

    useEffect(() => {
        if (!loading) {
            setHasMore(jobList.length < total)
        }
    }, [loading, jobList, total])

    useEffect(() => {
        if (scrollToJob) {
            setTimeout(() => {
                const elt = document.querySelector(`.job-list-item[data-job-id="${scrollToJob}"]`)
                if (elt) {
                    elt.scrollIntoView({
                        behavior: "smooth",
                        block: "center"
                    })
                }
                setScrollToJob(undefined)
            }, 200)
        }
    }, [scrollToJob])

    const fetchJobList = (pageNum: number) => {
        setCommandError(null)
        const command = new GetAdminJobListFilterCommand(filter ?? {})
        _adminJobClient.getJobList(command, pageNum, PAGING_SIZE)
            .then((response) => {

                const data = response.jobs ?? []

                if (pageNum > 0) {
                    setJobList(currentJobList => [...currentJobList, ...data])
                } else {
                    setJobList(data)
                }

                setTotal(response.total ?? 0)
            })
            .catch((reason) => {
                if (reason instanceof CommandError && reason.errors !== undefined) {
                    setCommandError(reason)
                } else {
                    setCommandError(new CommandError({ errors: [new CommandErrorDetail({ errorCode: ValidationErrorCode.UnexpectedError, errorMessage: reason?.toString() })] }))
                }
            })
            .finally(() => toggleLoading(false))
    }

    const getMoreData = () => {
        if (!loading) {
            const nextPage = currentPageNum + 1
            setCurrentPageNum(nextPage)
            fetchJobList(nextPage)
        }
    }

    const refresh = () => {
        toggleLoading(true)
        setCurrentPageNum(0)
        setHasMore(true)
        setJobList([])
        setTotal(0)
        fetchJobList(0)
    }

    const onHideEditJob = (needRefresh: boolean | undefined) => {
        const id = editJob!.id
        setEditJob(undefined)
        if (needRefresh) {
            refresh()
        }
        else {
            setScrollToJob(id)
        }
    }

    const renderContent = () => {
        if (loading) {
            return <div className="spinner-container"><Spinner animation="border" variant="primary" role="status" /></div>
        }

        if (commandError) {
            return <ValidationErrorPanel error={commandError} noBullet className="d-flex justify-content-center" />
        }

        return (
            <InfiniteScroll
                dataLength={jobList.length}
                next={getMoreData}
                className="job-list-results-items"
                hasMore={hasMore}
                loader={<></>}
            >
                {jobList.length == 0
                    ? <div className="job-list-results-noitems">Aucune offre d'emploi</div>
                    :
                    jobList.map((job, i) =>
                        <JobCard key={job.id} job={job} onEdit={() => setEditJob(job)} />
                    )
                }
            </InfiniteScroll>
        )
    }

    if (editJob) {
        return <EditableJobCard jobId={editJob.id!} hide={onHideEditJob} />
    }

    return (
        <div className="job-list">
            <Card className="mb-2 mt-2">
                <Card.Body>
                    <Card.Title>
                        Filtre des offres d'emploi
                    </Card.Title>
                    <FilterJobs initialFilter={filter} onFilterChanged={(filter) => setFilter({ ...filter })} />
                </Card.Body>
            </Card>
            <Card className="mb-2 mt-2 job-list-results">
                <Card.Body>
                    <Card.Title className="job-list-results-title">
                        <div>Résultats {jobList.length} / {total}</div>
                    </Card.Title>
                    {renderContent()}
                </Card.Body>
            </Card>
            <GoTop />
        </div>
    )
}