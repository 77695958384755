import { Route, Routes } from "react-router";
import { ReferentialProvider } from "./core/ReferentielProvider";

import AdminLayout from "./layout/AdminLayout";
import AnonymousLayout from "./layout/AnonymousLayout";
import { AdminCandidates } from "./pages/adminCandidates";
import { AdminEvents } from "./pages/adminEvents";
import { AdminOrganizations } from "./pages/adminOrganizations"
import { AdminJobs } from "./pages/adminJobs";
import { Home } from "./pages/home";
import { Impersonation } from "./pages/impersonation";
import { LoginPage } from "./pages/Login";


const AppRoutes = () => {


  return (<>


    <Routes>
      <Route path="" element={<AdminLayout><Home /></AdminLayout>} ></Route>
      <Route path="/" element={<AdminLayout><Home /></AdminLayout>} ></Route>
      <Route path="/adminCandidates" element={<AdminLayout><AdminCandidates /></AdminLayout>} ></Route>

      <Route path="/impersonation" element={<AdminLayout><Impersonation /></AdminLayout>} ></Route>
      <Route path="/adminEvents" element={<AdminLayout><AdminEvents /></AdminLayout>} ></Route>
      <Route path="/adminOrganizations" element={<AdminLayout><AdminOrganizations /></AdminLayout>}></Route>
      <Route path="/adminJobs" element={<AdminLayout><AdminJobs /></AdminLayout>} ></Route>
      <Route path="/login" element={<AnonymousLayout><LoginPage /></AnonymousLayout>}> </Route>
    </Routes>

  </>)
}



export default AppRoutes;
