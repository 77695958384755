import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAuthDataContext } from "../../core/AuthDataProvider";
import { AdminClient, ConnectResponse, UserClient } from "../../services";
import connect from "../../assets/connect.webp"
import { Alert } from "react-bootstrap";
import { ITokenManager } from "../../services/ITokenManager";


export function Login() {
    const _userClient = new AdminClient();

    const { onLogin, onLogout } = useAuthDataContext();
    const [error, setError] = useState("");
    const [userName, setUserName] = useState<string>("");
    const [passWord, setPassword] = useState<string>("");
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const handleSubmit = () => {

        setError("");
        _userClient.connect(userName, passWord).then((res: ConnectResponse) => {
            if (res.payload != null) {
                onLogin({ email: res?.payload?.email ?? "", token: res?.payload?.token ?? "", renewToken: res?.payload?.renewToken ?? "", role: (res.payload?.sa ? "superadmin" : ""), firstName: res.payload.firstName ?? "" })
                var redirectUrl = searchParams.get("redirectUrl") ?? "";
                if (redirectUrl && redirectUrl !== '/login') {
                    navigate(redirectUrl);
                } else {
                    navigate('/')
                }

            } else {
                setError(res.message ?? "")
                onLogout();
            }
        });
    };
    return (
        <>


            <div className="container">
                <div className="login-box">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="logo">
                                <span className="logo-font">Support Cyberjobs</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <br />
                            <h3 className="header-title">Page de connexion</h3>
                            <form className="login-form">

                                {error && <Alert key="danger" variant="danger">
                                    {error}
                                </Alert>}
                                <div className="form-group">
                                    <input value={userName} className="form-control" onChange={(e) => setUserName(e.target.value)} type="text" name="email" />
                                </div>
                                <div className="form-group">
                                    <input value={passWord} className="form-control" onChange={(e) => setPassword(e.target.value)} type="password" name="password" />

                                </div>
                                <div className="form-group">
                                    <button className="btn btn-primary btn-block" onClick={(e) => {
                                        handleSubmit(); e.preventDefault();
                                    }}>Se connecter</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>


        </>)
}