
import { DateTime } from "luxon";
import { useEffect } from "react";
import { useState } from "react";
import { Badge, Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { useAuthDataContext } from "../../core/AuthDataProvider";
import { useReferentielDataContext } from "../../core/ReferentielProvider";
import { AdminCandidateResponseModel, IUpdateAdminTodoCommand, CandidateClient, AdminCandidateClient, UpdateAdminTodoCommand, CreateAdminTodoCommand, CandidateTodoAdminState } from "../../services";

type Props = {
    hide: () => void;
    candidate: AdminCandidateResponseModel;
    onUpdate: (candidate: AdminCandidateResponseModel) => void;
};


export const TodoAdminModal = ({ hide, candidate, onUpdate }: Props) => {
    const [rappelData, setRappelData] = useState<IUpdateAdminTodoCommand | undefined>({ ...candidate.lastTodoAdmin });
    const adminCandidateClient = new AdminCandidateClient();
    const [isLoading, setIsLoading] = useState(true);
    const [id, setId] = useState(candidate.lastTodoAdmin?.id);
    const { adminTodoStateReferentiel } = useReferentielDataContext();
    useEffect(() => {

    }, [id])

    const addUpdateRappel = async () => {
        if (id) {
            var res = await adminCandidateClient.updateTodoAdmin(id, UpdateAdminTodoCommand.fromJS(rappelData))
            onUpdate(res);
        } else {
            var res = await adminCandidateClient.createTodoAdmin((candidate.candidateId ?? ""), CreateAdminTodoCommand.fromJS(rappelData))
            onUpdate(res);
        }
        hide();
    }
    console.log(rappelData);


    return (<Modal show={true} size="lg" fullscreen="lg-down" onHide={() => hide()}>

        <Modal.Header closeButton>
            <Modal.Title>{id ? "Modifier" : "Ajouter un rappel"} pour  <Badge>{candidate.firstName} {candidate.lastName}</Badge></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col md="6">
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Date de rappel"
                        className="mb-3">
                        <Form.Control
                            className="mt-2"
                            required
                            value={rappelData?.dueDate ? DateTime.fromJSDate(rappelData?.dueDate).toFormat("yyyy-MM-dd") : ""}
                            type="date"
                            onChange={(e) => setRappelData(s => ({ ...s, ["dueDate"]: DateTime.fromISO(e.target.value).toJSDate() }))}
                        >
                        </Form.Control>
                    </FloatingLabel>
                </Col>
                <Col md="6">
                    <Form.Label>Statut du rappel</Form.Label>
                    <Select className="w-50" options={adminTodoStateReferentiel}
                        onChange={(e) => setRappelData(s => ({ ...s, ["candidateTodoAdminState"]: e?.value ? (e.value as unknown as CandidateTodoAdminState) : undefined }))}
                        value={adminTodoStateReferentiel.find(d => d.value == CandidateTodoAdminState[(rappelData?.candidateTodoAdminState ?? CandidateTodoAdminState.TODO)])}
                    />

                </Col>
            </Row>
            <FloatingLabel
                controlId="floatingInput"
                label="Commentaire"
                className="mb-3">
                <Form.Control
                    className="mt-2"
                    required
                    as="textarea"
                    rows={5}
                    value={rappelData?.comment}

                    onChange={(e) => setRappelData(s => ({ ...s, ["comment"]: e.target.value }))}
                >
                </Form.Control>
            </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
            <Button className="mx-1" onClick={() => hide()} variant="disabled">Annuler</Button>
            <Button className="mx-1" onClick={() => addUpdateRappel()} variant="primary">{id ? "Modifier" : "Ajouter"}</Button>
        </Modal.Footer>
    </Modal>
    );
}