import {
  faClockRotateLeft,
  faGraduationCap,
  faGrip,
  faPlus,
  faRotateLeft,
  faRotateRight,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { EditCandidateInfosModelContext } from "./editCandidateInfosModelContext";
import { TimeLineCardEditor } from "./TimeLineCardEditor";
import { IEditableTimeLineCardModel } from "./IEditableTimeLineCardModel";
import { DraggableDirection, DraggableTimeLineContext, TimeLineContext } from "./TimeLineContext";
import { TimeLineDragContainer } from "./TimeLineDragContainer";

type TimeLineProps = {
  isLoading: boolean;
  data: IEditableTimeLineCardModel[][];
};
type TimeLineYearsProps = {
  data: IEditableTimeLineCardModel[][];
};
type LabelProps = {
  title: string;
  icon: JSX.Element;
  reverse?: boolean;
};

export const TimeLine = ({ isLoading, data }: TimeLineProps) => {

  const { editMode } = useContext(EditCandidateInfosModelContext)
  const [empty, setEmpty] = useState(false)

  useEffect(() => {
    if (!isLoading && editMode && data !== undefined) {
      setEmpty(data.length == 0)
    } else {
      setEmpty(false)
    }
  }, [editMode, isLoading, data])

  return (
    <div className="candidate-modal-timeline-container">
      <EditButtonBar />
      <div className="candidate-modal-timeline-labels">
        <Label
          title="Expériences"
          icon={<FontAwesomeIcon icon={faClockRotateLeft} />}
          reverse
        />
        <Label
          icon={<FontAwesomeIcon icon={faGraduationCap} />}
          title="Formations"
        />
      </div>
      {empty ? <EmptyTimeLineYears /> : <TimeLineYears data={data} />}
      <LoadingSpinner isLoading={isLoading} />
      <EditButtonBar />
    </div>
  );
};

const EditButtonBar = () => {
  const { editMode, toggleEditMode } = useContext(EditCandidateInfosModelContext)
  const { saveChanges, saveDisabled, undo, redo, canUndo, canRedo } = useContext(TimeLineContext)
  const [hide, toggleHide] = useState(true)

  useEffect(() => {
    toggleHide(!editMode)
  }, [editMode])

  if (hide) {
    return <></>
  }

  return (
    <div className="candidate-modal-timeline-edit-button-bar">
      <div className="candidate-modal-timeline-edit-button-bar-history-navigator">
        <FontAwesomeIcon icon={faRotateLeft} className={`candidate-modal-timeline-edit-button-bar-history-navigator-icon ${canUndo ? 'enabled' : ''}`} onClick={() => undo()} />
        <FontAwesomeIcon icon={faRotateRight} className={`candidate-modal-timeline-edit-button-bar-history-navigator-icon ${canRedo ? 'enabled' : ''}`} onClick={() => redo()} />
      </div>
      <Button className="mx-1 mt-1" variant="primary" disabled={saveDisabled} onClick={() => saveChanges()}>Valider</Button>
      <Button className="mx-1 mt-1" variant="secondary" onClick={() => toggleEditMode(false)}>Annuler</Button>
    </div>
  )
}

const Label = ({ title, icon, reverse }: LabelProps) => {
  return (
    <div className={`candidate-modal-timeline-label ${reverse ? "reverse" : ""}`}>
      <span style={{ margin: "0 15px" }}>{icon}</span>
      <h5>{title}</h5>
    </div>
  );
};

const LoadingSpinner = ({ isLoading }: { isLoading: boolean }) => {
  if (isLoading)
    return (
      <div style={{ width: "100%", textAlign: "center" }}>
        <Spinner style={{ color: "rgb(220, 220, 220)" }} animation="border" />
      </div>
    );
  return null;
};

const EmptyTimeLineYears = () => {
  return (
    <div className="candidate-modal-timeline-content edit-mode">
      <Line />
      <div id="time-line-wrapper" className="candidate-modal-timeline">
        <div className="candidate-modal-timeline-year">
          <TimeLineCards type={'job'} year={undefined} />
          <Date>?</Date>
          <TimeLineCards type={'education'} year={undefined} />
        </div>
      </div>
    </div>
  )
}

const TimeLineYears = ({ data }: TimeLineYearsProps) => {

  const { editMode } = useContext(EditCandidateInfosModelContext)

  return (
    <div className={`candidate-modal-timeline-content ${editMode ? 'edit-mode' : ''}`}>
      <Line />
      <div id="time-line-wrapper" className="candidate-modal-timeline">
        {data.map((item, i) => {
          return <Year data={item} key={i} />;
        })}
      </div>
    </div>
  );
};

const Line = () => {
  const timeLineEl = document.getElementById("time-line-wrapper");

  const getLineBoundings = () => {
    const firstChild = timeLineEl?.children[0];
    const lastChild = timeLineEl?.children[timeLineEl?.children.length - 1];
    const firstChildHeight = firstChild?.clientHeight;
    const lastChildHeight = lastChild?.clientHeight;

    if (!firstChildHeight || !lastChildHeight) return;

    return {
      height: `calc(100% - (${firstChildHeight / 2 + lastChildHeight / 2}px))`,
      marginTop: firstChildHeight / 2,
      marginBottom: lastChildHeight / 2,
    };
  };

  return (
    <div
      className="candidate-modal-timeline-line"
      style={{
        height: getLineBoundings()?.height,
        marginTop: getLineBoundings()?.marginTop,
        marginBottom: getLineBoundings()?.marginBottom,
      }}
    />
  );
};

const Year = ({ data }: { data: IEditableTimeLineCardModel[] }) => {
  const educations = data.filter((i) => i.type === "education");
  const jobs = data.filter((i) => i.type === "job");

  return (
    <div className="candidate-modal-timeline-year">
      <TimeLineCards data={jobs} type={'job'} year={data[0].year} />
      <Date>{data[0].year && data[0].year != "" ? data[0].year : "?"}</Date>
      <TimeLineCards data={educations} type={'education'} year={data[0].year} />
    </div>
  );
};

type TimeLineCardsProps = {
  data?: IEditableTimeLineCardModel[]
  type: string
  year?: string
}

const TimeLineCards = ({ data, type, year }: TimeLineCardsProps) => {
  const { addCard } = useContext(TimeLineContext)
  const [empty, setEmpty] = useState(true)

  useEffect(() => {
    setEmpty(data == undefined || data.length === 0)
  }, [data])

  const add = (cardId: number | null = null) => {
    addCard({ type: type, year: year }, cardId, cardId != null)
  }

  return (
    <TimeLineDragContainer type={type} year={year}>
      <TimeLineSeparator onAdd={() => add()} />
      {empty && <TimeLineDragContainer type={type} year={year} />}
      {data?.map((item, i) => (
        <div key={item.id}>
          <TimeLineCard item={item} />
          <TimeLineSeparator onAdd={() => add(item.id)} />
        </div>
      ))
      }
    </TimeLineDragContainer >
  );
};

const TimeLineCard = ({ item }: { item: IEditableTimeLineCardModel }) => {
  const { openedCards, moveCard } = useContext(TimeLineContext)
  const { draggableItem, setDraggableItem, isOver, direction, setPosY } = useContext(DraggableTimeLineContext)
  const { editMode } = useContext(EditCandidateInfosModelContext)
  const textSplited = item.description?.split("\n");
  const [edit, toggleEdit] = useState<boolean>(false)
  const [drag, setDrag] = useState(false)

  const dragStart = (e: any) => {
    setDraggableItem(item)
    e.dataTransfer.effectAllowed = "move"
  }

  useEffect(() => {
    if (!editMode) {
      setDrag(false)
    }
  }, [editMode])

  useEffect(() => {
    setDrag(isOver && draggableItem != undefined && draggableItem.id == item.id)
  }, [draggableItem])

  useEffect(() => {
    if (editMode && !edit) {
      const opened = openedCards?.some(x => x == item.id)
      toggleEdit(opened)
    }
  }, [editMode, edit, openedCards])

  useEffect(() => {
    if (!editMode) {
      toggleEdit(false)
    }
  }, [editMode])

  const onClick = () => {
    if (editMode) {
      toggleEdit(true)
    }
  }

  const dragEnter = (e: any) => {
    console.log('dragEnter card', { e, draggableItem })
    e.preventDefault()
    if (draggableItem !== undefined) {
      e.dataTransfer.dropEffect = "move"
      setPosY(e.screenY)
      const card = { ...draggableItem, year: item.year, type: item.type }
      moveCard(card, item, direction == DraggableDirection.Down)
    }
  }

  if (edit) {
    return <TimeLineCardEditor drag={drag} item={item} onHide={() => toggleEdit(false)} />
  }

  return (
    <div
      className={`candidate-modal-timeline-card ${item.type === "education" ? "left" : ""} ${drag ? "drag" : ""}`}
      onClick={() => onClick()}
      onDragEnter={(e) => dragEnter(e)}
    >
      {item?.relevant &&
        <div className="candidate-modal-timeline-card-recommended">
          <FontAwesomeIcon icon={faStar} />
        </div>
      }
      {
        editMode &&
        <div draggable className="candidate-modal-timeline-card-grab" onDragStart={(e) => dragStart(e)}>
          <FontAwesomeIcon icon={faGrip} />
        </div>
      }
      <div className="candidate-modal-timeline-card-wrapper">
        {item.title && <h6>{item.title}</h6>}
        {item.subTitle && <span className="candidate-modal-timeline-card-subtitle">{item.subTitle}</span>}
        {item.subTitle && item.organization && <span>&nbsp;-&nbsp;</span>}
        {item.organization && <span className="candidate-modal-timeline-card-organization">{item.organization}</span>}
        {
          textSplited?.map((x, i) => (
            <p key={i} style={{ marginTop: "5px" }}>
              {x}
            </p>
          ))
        }
      </div>
    </div >
  );
};

const TimeLineSeparator = ({ onAdd }: { onAdd: () => void }) => {

  const { editMode } = useContext(EditCandidateInfosModelContext)
  const [hide, toggleHide] = useState(true)

  useEffect(() => {
    toggleHide(!editMode)
  }, [editMode])

  if (hide) {
    return <></>
  }

  return (
    <div className="candidate-modal-timeline-card-separator">
      <span className="candidate-modal-timeline-card-separator-line"></span>
      <FontAwesomeIcon icon={faPlus} className="candidate-modal-timeline-card-separator-icon" onClick={(e) => onAdd()} />
      <span className="candidate-modal-timeline-card-separator-line"></span>
    </div>
  )
}

const Date = ({ children }: any) => {
  return <div className="candidate-modal-timeline-date">{children}</div>;
};
