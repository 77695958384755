import { useContext } from "react"
import { DraggableDirection, DraggableTimeLineContext, TimeLineContext } from "./TimeLineContext"

type DragContainerProps = {
    type: string
    year?: string
    children?: any
}

export const TimeLineDragContainer = ({ type, year, children }: DragContainerProps) => {
    const { moveCard, updateCard } = useContext(TimeLineContext)
    const { draggableItem, setDraggableItem, setIsOver, direction, setPosY } = useContext(DraggableTimeLineContext)

    const dragEnter = (e: any) => {
        e.preventDefault()
        if (draggableItem !== undefined) {
            e.dataTransfer.dropEffect = "move"
            setPosY(e.screenY)
            const card = { ...draggableItem, year: year, type: type }
            moveCard(card, null, direction == DraggableDirection.Down)
        }
    }

    const drop = (e: any) => {
        if (draggableItem !== undefined) {
            const item = { ...draggableItem, year: year, type: type }
            updateCard(item, false)
            setDraggableItem(undefined)
        }
        setIsOver(false)
    }

    const dragOver = (e: any) => {
        e.preventDefault()
        setIsOver(true)
    }

    const dragLeave = (e: any) => {
        e.preventDefault()
        setIsOver(false)
    }

    return (
        <section
            className="candidate-modal-timeline-cards-wrapper"
            onDragEnter={(e) => dragEnter(e)}
            onDragOver={(e) => dragOver(e)}
            onDrop={(e) => drop(e)}
            onDragLeave={(e) => dragLeave(e)}>
            {children}
        </section>
    )
}