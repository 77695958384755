import { MutableRefObject, useRef } from "react"
import { Controller, useFormContext } from "react-hook-form"
import ReactQuill, { Quill } from "react-quill"
import { EditJobForm } from "./types"
const QuillImage = require("quill-emoji")

Quill.register('modules/emoji', QuillImage)

export const useEditableJobDescription = () => {
    const quillRefDescription = useRef<any>(null)
    const quillRefProfileDescription = useRef<any>(null)

    return {
        quillRefDescription,
        quillRefProfileDescription,
        EditableJobDescriptionPanel: () => <EditableJobDescriptionPanel quillRefDescription={quillRefDescription} quillRefProfileDescription={quillRefProfileDescription} />
    }
}

type EditableJobDescriptionPanelProps = {
    quillRefDescription: MutableRefObject<any>
    quillRefProfileDescription: MutableRefObject<any>
}

const EditableJobDescriptionPanel = ({ quillRefDescription, quillRefProfileDescription }: EditableJobDescriptionPanelProps) => {
    const { control } = useFormContext<EditJobForm>()

    return (
        <div className="d-flex w-100 gap-2 description">
            <div className="w-100">
                <h6>Description du poste</h6>
                <Controller
                    control={control}
                    name="description"
                    rules={{ required: "Veuillez renseigner la description" }}
                    render={({ field: { value, ref, ...field } }) => (
                        <ReactQuill
                            value={value as any}
                            ref={(r) => {
                                ref(r)
                                quillRefDescription.current = r
                            }}
                            {...field}
                        />
                    )} />

            </div>
            <div className="w-100">
                <h6>Profil recherché</h6>
                <Controller
                    control={control}
                    name="profileDescription"
                    render={({ field: { value, ref, ...field } }) => (
                        <ReactQuill
                            value={value as any}
                            ref={(r) => {
                                ref(r)
                                quillRefProfileDescription.current = r
                            }}
                            {...field}
                        />
                    )} />
            </div>
        </div>
    )
}