import { useState } from "react"
import { Button, FloatingLabel, Form, Modal, Spinner } from "react-bootstrap"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useAuthDataContext } from "../../core/AuthDataProvider"
import { usePasswordValidation } from "../../hooks/usePasswordValidation"
import { renderErrors } from "../../hooks/useReactHookForm"
import { CommandError, UserClient } from "../../services"
import { ValidationErrorPanel } from "../ValidationErrorPanel/ValidationErrorPanel"
import { ChangePasswordFormType } from "./types"

type ChangePasswordModalProp = {
    onHide: () => void
}

export const ChangePasswordModal = (props: ChangePasswordModalProp) => {
    useAuthDataContext();
    const userClient = new UserClient()

    const [submiting, toggleSubmiting] = useState(false)
    const [submitingFailed, setSubmitingFailed] = useState<CommandError | any | null>(null)
    const methods = useForm<ChangePasswordFormType>({
        mode: 'all'
    })
    const { handleSubmit, formState: { errors } } = methods

    const handleClose = () => props.onHide()

    const submit = async (form: ChangePasswordFormType) => {
        toggleSubmiting(true)

        let success = false

        try {
            await userClient.changePassword2(form.oldPassword, form.newPassword)
            success = true
        } catch (error) {
            setSubmitingFailed(error)
        }
        finally {
            toggleSubmiting(false)
            if (success) {
                handleClose()
            }
        }
    }

    return (
        <FormProvider {...methods}>
            <Modal show={true} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Modifier mon mot de passe
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ChangePasswordForm />
                    {renderErrors(errors)}
                    <ValidationErrorPanel error={submitingFailed} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={handleSubmit(submit)}>
                        {submiting && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />}
                        Modifier le mot de passe
                    </Button>
                </Modal.Footer>
            </Modal>
        </FormProvider>
    )
}

const ChangePasswordForm = () => {
    const { t } = useTranslation()
    const { register, watch } = useFormContext<ChangePasswordFormType>()
    const passwordValidationRules = usePasswordValidation()

    const newPassword = watch('newPassword')

    return (
        <div className="d-flex flex-column gap-2">
            <FloatingLabel
                label="Mot de passe actuel">
                <Form.Control
                    type="password"
                    autoComplete="current-password"
                    {...register("oldPassword", { required: t('ChangePasswordForm.OldPassword.Mandatory') as string })}
                />
            </FloatingLabel>
            <FloatingLabel
                label="Nouveau mot de passe">
                <Form.Control
                    type="password"
                    autoComplete="new-password"
                    {...register("newPassword", passwordValidationRules())}
                />
            </FloatingLabel>
            <FloatingLabel
                label="Mot de passe actuel">
                <Form.Control
                    type="password"
                    {...register("confirmPassword", {
                        required: 'Veuillez confirmer votre mot de passe', validate: (v) => {
                            console.log('validation', { v, newPassword, equal: v === newPassword })
                            return v === newPassword || t('ChangePasswordForm.ConfirmPassword.NotMatch') as string
                        }
                    })}
                />
            </FloatingLabel>
        </div>
    )
}