import React, { useEffect, useState } from 'react';
import { CommandError, CommandErrorDetail, ValidationErrorCode } from '../../services/';
import "./ValidationErrorPanel.scss";
import { useCommandErrorDetailMessage } from '../../hooks/useCommandError'

type ValidationErrorPanelProps = {
    error: CommandError | any | null | undefined
    noBullet?: boolean
    className?: string
}

export const ValidationErrorPanel = ({ error, noBullet, className }: ValidationErrorPanelProps) => {

    const [errorList, setErrorList] = useState<(string | undefined)[]>([])

    const getCommandErrorDetailMessage = useCommandErrorDetailMessage()

    useEffect(() => {
        setErrorList([])

        if (error) {
            if (error?.errors === undefined) {
                error = new CommandError({ errors: [new CommandErrorDetail({ errorCode: ValidationErrorCode.UnexpectedError, errorMessage: error })] })
            }
            const list = error.errors!.map((detail: CommandErrorDetail) => {
                return getCommandErrorDetailMessage(detail)
            })
            setErrorList(list)
        }
    }, [error])

    if (errorList.length == 0) {
        return <></>
    }

    return (
        <div className={`validation-error-panel ${className ?? ''} `}>
            <ul className={noBullet ? "list-unstyled" : "list-inline"}>
                {errorList.map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </ul>
        </div>
    )
}