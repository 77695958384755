import { useState } from "react";
import { Button, FloatingLabel, Form, Modal, Spinner } from "react-bootstrap";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useAuthDataContext } from "../../core/AuthDataProvider";
import { renderErrors } from "../../hooks/useReactHookForm";
import { AdminOrganizationClient, CommandError, CommandErrorDetail, CreateOrganizationCommand, ICreateOrganizationCommand, OrganizationType, UserClient, ValidationErrorCode } from "../../services";
import './addOrganizationModal.scss';

type AddOrganizationModal = {
    onHide: (refresh?: boolean) => void
}

export const AddOrganizationModal = ({ onHide }: AddOrganizationModal) => {
    useAuthDataContext();
    const adminOrganizationClient = new AdminOrganizationClient();
    const [submiting, toggleSubmiting] = useState(false)
    const [submitingFailed, setSubmitingFailed] = useState<CommandError | null>(null)
    const methods = useForm<ICreateOrganizationCommand>({ mode: 'onBlur' })
    const { handleSubmit, getValues } = methods

    const onConfirm = async () => {
        toggleSubmiting(true)

        let success = false

        try {

            const form = getValues()
            const command = new CreateOrganizationCommand({ ...form })
            await adminOrganizationClient.create(command)
            success = true
        } catch (error) {
            if (error instanceof CommandError) {
                setSubmitingFailed(error)
            }
            else {
                setSubmitingFailed(new CommandError({ errors: [new CommandErrorDetail({ errorCode: ValidationErrorCode.UnexpectedError, errorMessage: error as string })] }))
            }
        }
        finally {
            toggleSubmiting(false)
            if (success) {
                onHide(true)
            }
        }
    }

    const submitingError = !submiting && submitingFailed
        ?
        <div className="error">
            Une erreur est survenue lors de l'enregistrement :
            <p>{submitingFailed.errors![0].errorCode}</p>
            <p>{submitingFailed.errors![0].errorMessage}</p>
        </div>
        : <></>

    return (
        <FormProvider {...methods} >
            <Modal show={true}>
                <Modal.Header>
                    Ajouter une organisation
                </Modal.Header>
                <Modal.Body>
                    <p>L'organisation ne sera pas visible tant que l'employé n'aura pas renseigné les informations.</p>
                    <p>L'employé recevra un email de bienvenue et pourra créer un mot de passe.</p>
                    <AddOrganizationForm />
                    {submitingError}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" disabled={submiting} onClick={() => onHide()}>Annuler</Button>
                    <Button variant="primary" disabled={submiting} onClick={(e) => handleSubmit(onConfirm)(e)}>
                        {submiting && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />}
                        Créer l'organisation
                    </Button>
                </Modal.Footer>
            </Modal>
        </FormProvider>
    )
}

const AddOrganizationForm = () => {
    const { register, formState: { errors } } = useFormContext<ICreateOrganizationCommand>()
    const userClient = new UserClient()

    /**
     * Vérifie l'unicité de l'adresse email
     * @param email 
     * @returns 
     */
    const checkEmailUnicity = async (email: string) => {
        let exists = true
        try {
            const response = await userClient.emailExists(email)
            exists = response.exists!
        } catch (error) {
        }

        return exists === false || "Cette adresse email est déjà utilisée."
    }

    /**
     * Valide l'adresse email
     * @param email 
     * @returns 
     */
    const validateEmail = async (email: string) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValid = re.test(String(email).toLowerCase());

        if (!isValid) {
            return "Cette adresse email est invalide"
        }

        return await checkEmailUnicity(email)
    }

    return (
        <div className="add-organization-form d-flex flex-column gap-2">
            <FloatingLabel
                label="Type d'organisation">
                <Form.Select {...register("type", { required: "Veuillez sélectionner le type d'organisation" })}>
                    <option></option>
                    <option value={OrganizationType.Association}>Association</option>
                    <option value={OrganizationType.Ecosystem}>Écosystème</option>
                    <option value={OrganizationType.Institution}>Institution</option>
                </Form.Select>
            </FloatingLabel>
            <FloatingLabel
                label="Nom de l'organisation">
                <Form.Control type="text" placeholder="Nom" {...register("name", { required: "Veuillez renseigner le nom de l'organisation" })} />
            </FloatingLabel>
            <FloatingLabel
                label="Nom de l'employé">
                <Form.Control type="text" placeholder="Nom" {...register("employeeLastName", { required: "Veuillez renseigner le nom de l'employé" })} />
            </FloatingLabel>
            <FloatingLabel
                label="Prénom de l'employé">
                <Form.Control type="text" placeholder="Prénom" {...register("employeeFirstName", { required: "Veuillez renseigner le prénom de l'employé" })} />
            </FloatingLabel>
            <FloatingLabel
                label="Téléphone de l'employé">
                <Form.Control type="text" placeholder="Téléphone" {...register("employeePhone", { required: "Veuillez renseigner le numéro de téléphone de l'employé" })} />
            </FloatingLabel>
            <FloatingLabel
                label="Email de l'employé">
                <Form.Control type="text" placeholder="Adresse email" {...register("employeeEmailAddress", { required: "Veuillez renseigner l'adresse email de l'employé", validate: async (value) => await validateEmail(value!) })} />
            </FloatingLabel>
            {renderErrors(errors)}
        </div>
    )
}