import { useEffect, useState } from "react"
import { useAuthDataContext } from "../../core/AuthDataProvider"
import { useReferentielDataContext } from "../../core/ReferentielProvider"
import { toQuillObject } from "../../hooks/useQuill"
import { AdminJobClient, AdminJobModel, CommandError, CommandErrorDetail, CreateOrUpdateJobCommand, IJobSalaryModel, JobLocationModel, JobOccupationModel, JobRequirementsModel, JobSalaryModel, JobStatus, JobTagModel, UpdateJobPresentationModel, ValidationErrorCode } from "../../services"
import { useOptions } from "./useOptions"
import { useValidators } from "./useValidators"
import { FormProvider, useForm } from "react-hook-form"
import { Button, Card, Form, Modal } from "react-bootstrap"
import { EditJobForm } from "./types"
import { useEditableJobDescription } from "./useEditableJobDescription"
import { ValidationErrorPanel } from "../ValidationErrorPanel/ValidationErrorPanel"
import { JobLink } from "../JobLink"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBackspace, faLink } from "@fortawesome/free-solid-svg-icons"
import { EditableJobIntegrationPanel } from "./EditableJobIntegrationPanel"
import { renderErrors } from "../../hooks/useReactHookForm"
import { EditableJobPrincipalPanel } from "./EditableJobPrincipalPanel"
import { EditableJobLocationPanel } from "./EditableJobLocationPanel"

type EditableJobCardFormProps = {
    job: AdminJobModel
    hide: (refresh?: boolean) => void
}

export const EditableJobCardForm = ({ job, hide }: EditableJobCardFormProps) => {
    useAuthDataContext()
    const client = new AdminJobClient()
    const referential = useReferentielDataContext()
    const options = useOptions()
    const { degrees, experiences, getSalary } = options
    const defaultSalary = getSalary(referential, job.salary)
    const { isValidContractType, isValidOptions, keepValidOccupations } = useValidators()
    const presentation = job.presentations === undefined ? undefined : job.presentations?.find(x => x.language == "fr_FR") ?? job.presentations?.length > 0 ? job.presentations[0] : undefined
    const methods = useForm<EditJobForm>({
        defaultValues: {
            title: presentation?.title,
            contractType: isValidContractType(referential, job.requirements?.contractType) ? job.requirements?.contractType : '',
            occupations: keepValidOccupations(referential, job.occupations),
            degreeRequired: isValidOptions(degrees, job.requirements?.degreeRequired) ? job.requirements?.degreeRequired : '',
            experienceRequired: isValidOptions(experiences, job.requirements?.experienceRequired) ? job.requirements?.experienceRequired : 'Aucune',
            salary: defaultSalary,
            salaryVisible: job.salary?.visible,
            avantage: job.salary?.avantage,
            skills: presentation?.tags?.map(x => x.tag) ?? new Array<string>(),
            teleworkingRate: job.teleworkingRate,
            startDate: job.startDate,
            predefinedLocationId: job.location?.predefinedLocationId,
            address: job.location?.address,
            zipCode: job.location?.zipCode,
            city: job.location?.city,
            region: job.location?.region,
            description: toQuillObject(presentation?.descriptionEnrichText, presentation?.descriptionPlainText),
            profileDescription: toQuillObject(presentation?.profileEnrichText, presentation?.profilePlainText)
        },
        mode: "all"
    })
    const { trigger, handleSubmit, formState: { errors } } = methods
    const [showRefuseConfirm, setShowRefuseConfirm] = useState(false)
    const [isSubmitting, setSubmitting] = useState(false)
    const [commandError, setCommandError] = useState<CommandError | null>(null)
    const { quillRefDescription, quillRefProfileDescription, EditableJobDescriptionPanel } = useEditableJobDescription()

    /**
     * Force la validation de tous les champs au chargement
     */
    useEffect(() => {
        trigger()
    }, [])

    /**
     * Scroll en haut de la page au chargement
     */
    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [job])

    const submit = async (data: EditJobForm) => {
        try {
            setSubmitting(true)
            setCommandError(null)

            const command = new CreateOrUpdateJobCommand({
                bypassBlockingValidationRules: true,
                startDate: data.startDate,
                location: new JobLocationModel({
                    predefinedLocationId: data.predefinedLocationId,
                    address: data.address,
                    zipCode: data.zipCode,
                    city: data.city,
                    region: data.region
                }),
                occupations: [...data.occupations.map(x => new JobOccupationModel({ occupation: x }))],
                teleworkingRate: data.teleworkingRate,
                salary: new JobSalaryModel({
                    ...getSalaryRange(data),
                    visible: data.salaryVisible,
                    avantage: data.avantage
                }),
                requirements: new JobRequirementsModel({
                    contractType: data.contractType,
                    degreeRequired: data.degreeRequired,
                    experienceRequired: data.experienceRequired
                }),
                presentation: new UpdateJobPresentationModel({
                    language: presentation?.language ?? "fr_FR",
                    title: data.title,
                    descriptionEnrichText: JSON.stringify(quillRefDescription.current.editor.getContents()),
                    profileEnrichText: JSON.stringify(quillRefProfileDescription.current.editor.getContents()),
                    tags: [...data.skills.map(x => new JobTagModel({ tag: x }))]
                })
            })
            console.log(command)
            await client.update(job.id!, command)
            setSubmitting(false)
            hide(true)
        } catch (error: any) {
            if (error instanceof CommandError) {
                setCommandError(error)
            } else {
                setCommandError(new CommandError({ errors: [new CommandErrorDetail({ errorCode: ValidationErrorCode.UnexpectedError, errorMessage: error?.toString() })] }))
            }
            setSubmitting(false)
        }
    }

    /**
     * Retourne la nouvelle fourchette de salaire.
     * Si la valeur n'a pas été modifiée, on conserve les valeurs actuelles de l'offre
     * @param data 
     * @returns 
     */
    const getSalaryRange = (data: EditJobForm): IJobSalaryModel => {
        if (data.salary != null && data.salary != undefined && data.salary != '') {
            if (data.salary !== defaultSalary) {
                const splitResult = data.salary.split('-')
                if (splitResult.length == 2) {
                    return { minValue: parseInt(splitResult[0]), maxValue: parseInt(splitResult[1]) }
                }
            }
            else {
                return { minValue: job.salary?.minValue, maxValue: job.salary?.maxValue }
            }
        }
        return {}
    }

    const refuse = async () => {
        try {
            setShowRefuseConfirm(false)
            setSubmitting(true)
            setCommandError(null)

            await client.refuse(job.id!)
            setSubmitting(false)
            hide(true)
        } catch (error: any) {
            if (error instanceof CommandError) {
                setCommandError(error)
            } else {
                setCommandError(new CommandError({ errors: [new CommandErrorDetail({ errorCode: ValidationErrorCode.UnexpectedError, errorMessage: error?.toString() })] }))
            }
            setSubmitting(false)
        }
    }

    return (
        <FormProvider {...methods}>
            <Modal show={showRefuseConfirm} onHide={() => setShowRefuseConfirm(false)}>
                <Modal.Body className="text-center">
                    Êtes-vous sûr de vouloir refuser cette offre ?<br />
                    L'offre ne sera plus visible par la suite.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-disabled" onClick={() => setShowRefuseConfirm(false)}>
                        Annuler
                    </Button>
                    <Button variant="secondary" onClick={() => refuse()}>
                        Confirmer
                    </Button>
                </Modal.Footer>
            </Modal>
            <Card className="mb-2 mt-2 editable-job-list-item-container">
                <Card.Body>
                    <Card.Title className="d-flex justify-content-between header">
                        <div>Édition d'une offre d'emploi</div>
                        <div className="d-flex align-items-center gap-2">
                            <FontAwesomeIcon icon={faBackspace} className="backward" onClick={() => hide()} title="Retour à la liste" />
                            {job?.status?.status == JobStatus.Published && <JobLink job={job} className="text-decoration-none"><FontAwesomeIcon icon={faLink} title="Voir l'annonce" /></JobLink>}
                        </div>
                    </Card.Title>
                    <div className="editable-job-list-item">
                        <Form onSubmit={handleSubmit(submit)} className="editable-job-list-item-wrapper">
                            <EditableJobIntegrationPanel integration={job.integration} />
                            <EditableJobPrincipalPanel job={job} referential={referential} options={options} />
                            <EditableJobLocationPanel job={job} referential={referential} />
                            <EditableJobDescriptionPanel />
                            <div className="d-flex flex-column mt-auto w-100 pb-2 bottom">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    {renderErrors(errors)}
                                    <ValidationErrorPanel error={commandError} />
                                </div>
                                <div className=" d-flex justify-content-center gap-2">
                                    <Button variant="outline-disabled" disabled={isSubmitting} onClick={(e) => hide()}>Annuler</Button>
                                    {job.status?.status === JobStatus.Unvalidated && <Button variant="secondary" type="button" disabled={isSubmitting} onClick={(e) => setShowRefuseConfirm(true)}>Refuser</Button>}
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>Enregistrer</Button>
                                </div>
                            </div>
                        </Form >
                    </div >
                </Card.Body >
            </Card >
        </FormProvider>
    )
}