import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faBan, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { ChangeEvent, useEffect, useState } from "react";
import { Badge, Button, ButtonGroup, Col, FloatingLabel, Form, InputGroup, Modal, Row, Tab, Tabs } from "react-bootstrap";
import SelectEditable from "react-select/async-creatable";
import Select from "react-select";
import { IAdminCandidateResponseModel, IAdminValidateCandidate, AdminValidateCandidate, CVStatus, ReferentialData, CountryResponseModel, WebProfileType, CandidateWebProfileModel } from "../../services";
import CandidateInterest from "./interestUpdate";


export interface IUpdateInfoCandidateProps {
  candidate: IAdminCandidateResponseModel | undefined;
  cancel: () => void;
  update: (candidate: IAdminValidateCandidate) => void;
  referential: {
    contractsType: ReferentialData[]
    regions: CountryResponseModel[]
    salaryOptions: ReferentialData[]
    schoolsOptions: ReferentialData[]
    experiencesOptions: ReferentialData[]
    jobTypesOptions: ReferentialData[]
  }
}



export function UpdateInfoCandidate({ candidate, cancel, update, referential }: IUpdateInfoCandidateProps) {


  const [validationInfo, setValidationInfo] = useState<IAdminValidateCandidate | undefined>(
    AdminValidateCandidate.fromJS({
      schools: candidate?.schools,
      isGeographicallyMobility: candidate?.mobility,
      mobilityContext: candidate?.mobilityContext ?? "France",
      regionsGeographicallyMobility: candidate?.mobilityRegions,
      salaryValue: candidate?.salaryExpected ?? "-",
      salaryVisible: candidate?.salaryVisible,
      missionsType: candidate?.missionsType,
      competencies: candidate?.competencies,
      firstName: candidate?.firstName,
      lastName: candidate?.lastName,
      contractTypes: candidate?.contractsType,
      candidateId: candidate?.candidateId ?? "",
      japdDone: candidate?.japdDone,
      statusCV: candidate?.cvStatus,
      publishDate: candidate?.publishedDate ?? DateTime.now().toJSDate(),
      experienceLevel: (candidate?.workExperienceTime ?? ""),
      totalExperienceLevel: (candidate?.totalWorkExperienceTime ?? ""),
      educationLevel: candidate?.educationDegree,
      webProfiles: candidate?.webProfiles,
      telephone: candidate?.telephone,
      actualRegion: candidate?.actualRegion,
      searchJob: candidate?.searchJob,
      activeSearch: candidate?.activeSearch
    }))
  const [regionsOptions, setRegionsOptions] = useState<((Option)[])>([]);
  const [tabSelected, setTabSelected] = useState<string>();
  const [linkedinUrl, setLinkedinUrl] = useState<string>()

  const experiencesOptions = referential.experiencesOptions.filter(x => x.value != "UNDEFINED")

  useEffect(() => {
    const linkedInProfile = candidate?.webProfiles?.find(x => x.type == WebProfileType.LinkedIn)
    if (linkedInProfile) {
      setLinkedinUrl(linkedInProfile.uri)
    }
  }, [candidate?.webProfiles])

  const unpublish = () => {
    setValidationInfo(s => ({ ...s, ["publishDate"]: undefined }))
  }
  const updatePublishedDate = (value: number) => {
    var _publishedDate = DateTime.now().startOf("week");
    _publishedDate = _publishedDate.plus({ week: value }).startOf("week");
    setValidationInfo(s => ({ ...s, ["publishDate"]: _publishedDate?.toJSDate() }))
  }
  useEffect(() => {

    var _ = referential.regions.map(s => {
      var _regions = (s.regions?.length == 0 ? [] : s.regions?.map(r => { return { "label": r.label, "value": r.label } as Option }) ?? []);

      if (_regions.length > 0) {
        var opt = {
          "label": s.label, "value": s.label,
          options: [{ label: s.label + " - Toutes régions", value: s.label }]
        }

        opt.options = opt.options?.concat(_regions)

        return opt as Option;
      } else {
        var onlylabel = {
          "label": s.label, "value": s.label
        }
        return onlylabel as Option;
      }


    });

    setRegionsOptions(_ as Option[])

    return () => {

    }
  }, [referential.regions])



  interface Option {
    readonly label: string;
    readonly value: string;
    readonly options?: Option[]


  }
  const filterSchools = (inputValue: string) => {
    console.log(inputValue);
    return referential.schoolsOptions.map(c => c as Option).filter(i =>
      i?.label?.toLowerCase().includes(inputValue.toLowerCase())
    );
  };


  const promiseOptions = (inputValue: string) =>
    new Promise<Option[]>((resolve) => {
      setTimeout(() => {
        resolve(filterSchools(inputValue));
      }, 300);
    });

  const handleLinkedInChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    let { webProfiles } = validationInfo!

    if (webProfiles?.some(x => x.type == WebProfileType.LinkedIn)) {
      const profile = webProfiles.find(x => x.type == WebProfileType.LinkedIn)
      webProfiles.splice(webProfiles.indexOf(profile!), 1)
    }

    if (value !== '') {
      webProfiles?.push(new CandidateWebProfileModel({ type: WebProfileType.LinkedIn, uri: value }))
    }

    setValidationInfo((current) => ({ ...current, webProfiles: webProfiles }))
  }

  const selectedJobTypes = referential.jobTypesOptions.flatMap(opt => opt.options ?? []).filter(d => validationInfo?.missionsType?.includes(d.value ?? ""))

  return <>
    <Modal.Body>

      <Tabs
        defaultActiveKey="personal"
        transition={false}
        id="noanim-tab-example"
        className="mt-3 w-100 nav-fill"
        variant="tabs"
        onSelect={(e) => setTabSelected(e ?? "")}

      >


        <Tab eventKey="personal" className="mt-2" title="Info Pers.">
          <Row>

            <Col md="6" xs="12">
              <FloatingLabel
                controlId="floatingInput"
                label="Prénom"
                className="mb-3"
              >

                <Form.Control
                  className="mt-2"
                  required
                  value={validationInfo?.firstName}
                  type="text"
                  onChange={(e) => setValidationInfo(s => ({ ...s, ["firstName"]: e.target.value }))}
                >
                </Form.Control>
              </FloatingLabel>
            </Col>
            <Col md="6" xs="12">
              <FloatingLabel
                controlId="floatingInput"
                label="Nom"
                className="mb-3"
              >
                <Form.Control
                  className="mt-2"
                  required
                  value={validationInfo?.lastName}
                  type="text"
                  onChange={(e) => setValidationInfo(s => ({ ...s, ["lastName"]: e.target.value }))}
                >
                </Form.Control>
              </FloatingLabel>
            </Col>

            <Col md="6" xs="12">
              <FloatingLabel
                controlId="floatingInput"
                label="Téléphone"
                className="mb-3"
              >
                <Form.Control
                  className="mt-2"
                  required
                  value={validationInfo?.telephone}
                  type="text"
                  onChange={(e) => setValidationInfo(s => ({ ...s, ["telephone"]: e.target.value }))}
                >
                </Form.Control>
              </FloatingLabel>
            </Col>
            <Col md="6" xs="12">


              <FloatingLabel
                controlId="floatingInput"
                label="Région actuelle"
                className="mb-3"
              >
                <Form.Control
                  className="mt-2"
                  required
                  value={validationInfo?.actualRegion}
                  as="select"
                  onChange={(e) => setValidationInfo(s => ({ ...s, ["actualRegion"]: e.target.value }))}
                >
                  <option value="">Sélectionnez une région</option>
                  {referential.regions.sort().map(r => <>
                    {r.regions && r.regions.length > 0 && <>
                      <optgroup className="optoinGroup" label={r.label}>
                        {r.regions.map(r => <option value={r.label}>{r.label}</option>)}
                      </optgroup></>}
                    {(!r.regions || r.regions.length == 0) && <>
                      <option className="optionGroup " value={r.label}>{r.label}</option>
                    </>

                    }

                  </>)}
                </Form.Control>

              </FloatingLabel>

            </Col>
          </Row>

          <Form.Label for="linkedinurl">Url Linkedin</Form.Label>
          <InputGroup className="mt-2">
            <Form.Control

              required
              id="linkedinurl"
              value={linkedinUrl}
              onChange={handleLinkedInChange}
            />
            <InputGroup.Text id="basic-addon1"><FontAwesomeIcon onClick={(d) => window.open(`https://www.google.fr/search?q=site:linkedin.com ${candidate?.firstName} ${candidate?.lastName}`)} icon={faLinkedin} /></InputGroup.Text>

          </InputGroup>
          <div className="row">
            <div className="col-3 d-flex align-items-baseline mt-3">
              <Form.Label>Japd</Form.Label>
              <ButtonGroup size="sm" aria-label="Basic example" className="w-100">

                <Button size="sm" variant={((validationInfo?.japdDone ?? undefined) === true) ? "success" : "outline-primary"} onClick={() => setValidationInfo(s => ({ ...s, ["japdDone"]: true }))} className="mx-1">Oui</Button>
                <Button size="sm" variant={((validationInfo?.japdDone ?? undefined) === false) ? "success" : "outline-primary"} onClick={() => setValidationInfo(s => ({ ...s, ["japdDone"]: false }))} className="mx-1">Non</Button>
                <Button size="sm" variant={(validationInfo?.japdDone === null || validationInfo?.japdDone === undefined) ? "success" : "outline-primary"}
                  onClick={() => setValidationInfo(s => ({ ...s, ["japdDone"]: undefined }))} className="mx-1">Ne sait pas</Button>

              </ButtonGroup>
            </div>

          </div>

        </Tab>
        <Tab eventKey="profil" className="mt-2" title="Profil.">
          <Row>
            <Col md="4">
              <FloatingLabel
                controlId="floatingInput"
                label="Niveau d'expérience en cyber"
                className="mb-3"
              >
                <Form.Control
                  className="mt-2"
                  required
                  value={validationInfo?.experienceLevel}
                  as="select"
                  onChange={(e) => setValidationInfo(s => ({ ...s, ["experienceLevel"]: e.target.value }))}
                >
                  <option value="">Sélectionnez le niveau d'expérience en cyber</option>
                  {experiencesOptions.sort().map(e => (
                    <option value={e.value}>{e.label}</option>)
                  )}
                </Form.Control>

              </FloatingLabel>
            </Col>
            <Col md="4">
              <FloatingLabel
                controlId="floatingInput"
                label="Niveau d'expérience"
                className="mb-3"
              >
                <Form.Control
                  className="mt-2"
                  required
                  value={validationInfo?.totalExperienceLevel}
                  as="select"
                  onChange={(e) => setValidationInfo(s => ({ ...s, totalExperienceLevel: e.target.value }))}
                >
                  <option value="">Sélectionnez le niveau d'expérience</option>
                  {experiencesOptions.sort().map(e => (
                    <option value={e.value}>{e.label}</option>)
                  )}
                </Form.Control>

              </FloatingLabel>
            </Col>
            <Col md="4">
              <FloatingLabel
                controlId="floatingInput"
                label="Niveau formation"
                className="mb-3"
              >
                <Form.Control
                  className="mt-2"
                  required
                  value={validationInfo?.educationLevel}
                  as="select"
                  onChange={(e) => setValidationInfo(s => ({ ...s, ["educationLevel"]: e.target.value }))}
                >
                  <option value="">Sélectionnez le niveau de formation</option>
                  <option value="Bac+2">Bac+2</option>
                  <option value="Bac+3">Bac+3</option>
                  <option value="Bac+4">Bac+4</option>
                  <option value="Bac+5">Bac+5</option>
                  <option value="Doctorat">Doctorat</option>
                  <option value="Master">Master</option>

                </Form.Control>
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>Ecoles</Form.Label>
              <SelectEditable isMulti loadOptions={promiseOptions}
                placeholder="Ecoles"
                defaultOptions={referential.schoolsOptions.map(c => c as Option)}
                onChange={(e) => setValidationInfo(s => ({ ...s, ["schools"]: e.map(s => s.value ?? "") }))}
                value={validationInfo?.schools?.map((c) => { return { label: c, value: c } }
                ).sort((a, b) => a.label.localeCompare(b.label))} />

            </Col>

          </Row>
          <Row>
            <Col md="12" xs="12">
              <Form.Label>Compétences</Form.Label>
              <SelectEditable isMulti
                placeholder="Compétences"
                onChange={(e) => setValidationInfo(s => ({ ...s, ["competencies"]: e.map(s => s.value ?? "") }))}
                value={validationInfo?.competencies?.map((c) => { return { label: c, value: c } }
                ).sort((a, b) => a.label.localeCompare(b.label))} />

            </Col>
          </Row>
          {candidate?.cleverConnectProfile?.skills && candidate.cleverConnectProfile.skills.length > 0 &&
            <Row>
              <Col md="12" xs="12">
                <Form.Label>Provenant du CV</Form.Label>
                <div>
                  {candidate?.cleverConnectProfile?.skills?.map((c) => <Badge bg="none" className="competency mx-1">{c}</Badge>)}
                </div>
              </Col>
            </Row>
          }
        </Tab>
        <Tab eventKey="search" className="mt-2" title="Rech. emploi">
          <Row>
            <Col md="6">
              <Form.Label>Statut de la recherche d'emploi</Form.Label>
              <ButtonGroup aria-label="Statut de la recherche d'emploi" className="w-100 mt-1">

                <Button variant={validationInfo?.searchJob === false ? "success" : "outline-primary"} onClick={() => setValidationInfo(s => ({ ...s, searchJob: false, activeSearch: undefined }))} className="mx-1">Pas en recherche</Button>
                <Button variant={validationInfo?.searchJob === true && validationInfo?.activeSearch === true ? "success" : "outline-primary"} onClick={() => setValidationInfo(s => ({ ...s, searchJob: true, activeSearch: true }))} className="mx-1"><FontAwesomeIcon className="me-1" icon={faSearch} />Recherche active</Button>
                <Button variant={validationInfo?.searchJob === true && validationInfo?.activeSearch === false ? "success" : "outline-primary"} onClick={() => setValidationInfo(s => ({ ...s, searchJob: true, activeSearch: false }))} className="mx-1"><FontAwesomeIcon className="me-1" icon={faSearch} />Recherche opportunité</Button>
              </ButtonGroup>
            </Col>

            {validationInfo?.searchJob === true && <>
              <Col md="6">
                <Form.Label>Statut CV</Form.Label>
                <ButtonGroup aria-label="Statut du CV" className="w-100 mt-1">
                  <Button variant={((validationInfo?.statusCV) === CVStatus.UP_TODATE ? "success" : "outline-primary")} onClick={() => setValidationInfo(s => ({ ...s, ["statusCV"]: CVStatus.UP_TODATE }))} className="mx-1">A jour</Button>
                  <Button variant={((validationInfo?.statusCV) === CVStatus.OUTDATED ? "success" : "outline-primary")} onClick={() => setValidationInfo(s => ({ ...s, ["statusCV"]: CVStatus.OUTDATED }))} className="mx-1">Pas à jour</Button>
                  <Button variant={((validationInfo?.statusCV) === CVStatus.LATER ? "success" : "outline-primary")} onClick={() => setValidationInfo(s => ({ ...s, ["statusCV"]: CVStatus.LATER }))} className="mx-1">Plus tard</Button>
                </ButtonGroup>
              </Col>
              <Col md="8" className="mt-2" xs="6">
                <Form.Label>Type(s) de postes</Form.Label>
                <Select className="" isMulti options={referential.jobTypesOptions}
                  placeholder="Type(s) de postes"
                  onChange={(e) => setValidationInfo(s => ({ ...s, ["missionsType"]: e.map(s => s.value ?? "") }))}
                  value={selectedJobTypes} />


              </Col>
              <Col md="7" className="mt-2" xs="6">
                <Form.Label>Type(s) de recherche</Form.Label>

                <Select className="" isMulti options={referential.contractsType}
                  placeholder="Type(s) de recherche"
                  onChange={(e) => setValidationInfo(s => ({ ...s, ["contractTypes"]: e.map(s => s.value ?? "") }))}
                  value={referential.contractsType.filter(d => validationInfo?.contractTypes?.includes(d.value ?? ""))} />
              </Col>
              <Col md="5" className="mt-2" xs="6">
                <Form.Label>Prétentions</Form.Label>
                <div className="d-flex align-items-baseline w-100">
                  <Select className="w-50" options={referential.salaryOptions}
                    onChange={(e) => setValidationInfo(s => ({ ...s, ["salaryValue"]: e?.value }))}
                    value={referential.salaryOptions.find(d => validationInfo?.salaryValue === d.value)} />
                  <Form.Check className="ms-3"
                    type="switch"
                    label={"Salaire Visible"}
                    id="visible salary"
                    checked={validationInfo?.salaryVisible ?? false}
                    onChange={(e) => setValidationInfo(s => ({ ...s, ["salaryVisible"]: e.target.checked }))}

                  />
                </div>
              </Col>
              <Col md="12" className="mt-2" xs="6">
                <div className="d-flex align-items-baseline w-100">
                  <Form.Label>Mobilité</Form.Label>
                  <Form.Check className="ms-3"
                    type="switch"
                    label={"Oui/Non"}
                    id="visible salary"
                    checked={validationInfo?.isGeographicallyMobility ?? false}
                    onChange={(e) => setValidationInfo(s => ({ ...s, ["isGeographicallyMobility"]: e.target.checked }))}

                  />
                </div>
                <div className="d-flex align-items-baseline w-100">


                  {validationInfo?.isGeographicallyMobility && <>

                    <div className="col-7">
                      <Select className="" isMulti isSearchable options={regionsOptions}
                        placeholder="Régions"
                        onChange={(e) => setValidationInfo(s => ({ ...s, ["regionsGeographicallyMobility"]: e.map(s => (s as Option).value ?? "") }))}
                        value={
                          regionsOptions.filter(d => d.options?.length == 0 && validationInfo?.regionsGeographicallyMobility?.includes((d as Option).value ?? "")).concat(
                            regionsOptions?.flatMap(s => s.options ?? []).filter(d => validationInfo?.regionsGeographicallyMobility?.includes((d as Option).value ?? "")))
                            ?.sort()

                        }
                      />
                    </div>
                  </>}


                </div>
              </Col>

            </>}
          </Row>
        </Tab>
        <Tab eventKey="interests" className="mt-2" title="Intérêt">

          {candidate?.candidateId && <CandidateInterest candidateId={candidate?.candidateId}></CandidateInterest>} </Tab>
      </Tabs >



    </Modal.Body >
    <Modal.Footer>
      {tabSelected !== "interests" && <Row>

        <Col md="12" className="d-flex align-items-baseline">
          <Form.Label>Publier dans :</Form.Label>
          <ButtonGroup className=" mt-1 predefined-filter">
            <a onClick={() => updatePublishedDate(0)} className="mx-1">CV Thèque Auj.</a>
            <a onClick={() => updatePublishedDate(1)} className="mx-1">CV Thèque Sem. Pro.</a>
            <a onClick={() => updatePublishedDate(-18)} className="mx-1">Communauté</a>
            <a onClick={() => unpublish()} className="mx-1 text-danger"> <FontAwesomeIcon className="me-1" icon={faBan} />Ne pas publier</a>
          </ButtonGroup>
        </Col>
        <Col md={{ span: 6, offset: 3 }}>
          <FloatingLabel
            controlId="floatingInput"
            label="Date de publication"
            className="mb-3">
            <Form.Control
              className="mt-2"
              required
              value={validationInfo?.publishDate ? DateTime.fromJSDate(validationInfo?.publishDate).toFormat("yyyy-MM-dd") : ""}
              type="date"
              onChange={(e) => setValidationInfo(s => ({ ...s, ["publishDate"]: DateTime.fromISO(e.target.value).toJSDate() }))}
            >
            </Form.Control>
          </FloatingLabel>
        </Col>
        <div className="text-end w-100">

          <Button className="mx-1" onClick={() => cancel()} variant="disabled">Annuler</Button>
          {validationInfo && <Button className="mx-1" onClick={() => update(validationInfo)} variant="primary">Valider le candidat</Button>}
        </div>

      </Row>}

    </Modal.Footer>

  </>

}