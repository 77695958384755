import { useEffect, useState } from "react";
import { Navbar } from "react-bootstrap";



export default function Footer() {

    return (
    <>
        <div className="bg-info mt-auto"  >
     
        </div>
    </>)
}