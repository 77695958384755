import React from "react";
import { ITimeLineCardModel } from "../../services";
import { IEditableTimeLineCardModel } from "./IEditableTimeLineCardModel";

export type TimeLineContextType = {
    openedCards: number[]
    closeCard: (cardId: number) => void
    cancelCardEdition: (card: IEditableTimeLineCardModel) => void
    addCard: (card: ITimeLineCardModel, sibblingCardId: number | null, after: boolean) => void
    updateCard: (card: IEditableTimeLineCardModel, draft: boolean) => void
    deleteCard: (card: IEditableTimeLineCardModel) => void
    saveChanges: () => void
    hasChanges: boolean
    saveDisabled: boolean
    undo: () => void
    redo: () => void
    canUndo: boolean
    canRedo: boolean
    moveCard: (card: IEditableTimeLineCardModel, destinationCard: IEditableTimeLineCardModel | null, after: boolean) => void
}

export const TimeLineContext = React.createContext<TimeLineContextType>(
    undefined as unknown as TimeLineContextType
)

export type DraggableTimeLineCardModel = {
    card: IEditableTimeLineCardModel
    posY: number
}

export enum DraggableDirection {
    Up,
    Down
}

export type DraggableTimeLineContextType = {
    draggableItem?: IEditableTimeLineCardModel
    setDraggableItem: (item: IEditableTimeLineCardModel | undefined) => void
    direction?: DraggableDirection
    setDirection: (direction: DraggableDirection) => void
    isOver: boolean
    setIsOver: (isOver: boolean) => void
    setPosY: (posY: number) => void
}


export const DraggableTimeLineContext = React.createContext<DraggableTimeLineContextType>(
    undefined as unknown as DraggableTimeLineContextType
)