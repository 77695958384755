import { useContext, useEffect } from "react";
import { useState } from "react";
import { useAuthDataContext } from "../../core/AuthDataProvider";
import {
  AdminCandidateResponseModel,
  CandidateClient,
  ISheetCandidateModel
} from "../../services";
import "./candidateInfosModal.scss";
import { EditCandidateInfosModelContext } from "./editCandidateInfosModelContext";
import { Header } from "./Header";
import { TimeLineEditor } from "./TimeLineEditor";

type Props = {
  hide: () => void;
  candidate: AdminCandidateResponseModel;
};

const CandidateInfosModal = ({ hide, candidate }: Props) => {
  const [candidateData, setCandidateData] = useState<ISheetCandidateModel | null>(null);
  const candidateClient = new CandidateClient();
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, toggleEditMode] = useState<boolean>(false)

  useEffect(() => {
    getCandidateData();
    toggleEditMode(false)
  }, [candidate.candidateId]);

  const getCandidateData = async () => {
    setIsLoading(true);

    if (candidate.candidateId) {
      await candidateClient
        .getSheetCandidate(candidate.candidateId)
        .then((res) => setCandidateData(res.sheet!));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleScroll("hidden");
  }, []);

  const handleScroll = (type: "hidden" | "unset") => {
    const body = document.body;
    body.style.overflow = type;
  };

  const handleHideModal = () => {
    handleScroll("unset");
    hide();
  };

  return (
    <div className="candidate-modal" onClick={handleHideModal}>
      <div
        className="candidate-modal-wrapper"
        onClick={(e) => e.stopPropagation()}
      >
        <EditCandidateInfosModelContext.Provider value={{ editMode: editMode, toggleEditMode: toggleEditMode }}>
          <Header candidate={candidate} handleHideModal={handleHideModal} />
          <TimeLineEditor candidateId={candidate?.candidateId ?? null} candidateData={candidateData} isLoading={isLoading} />
        </EditCandidateInfosModelContext.Provider>
      </div>
    </div>
  );
};

export default CandidateInfosModal;
