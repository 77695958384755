import { useEffect, useState } from "react"
import { Table } from "react-bootstrap"
import { JobIntegrationModel } from "../../services"

type JobIntegrationPanelProps = {
    integration: JobIntegrationModel | undefined
}

type AdditionalDataType = {
    label: string
    value: string
}

export const EditableJobIntegrationPanel = ({ integration }: JobIntegrationPanelProps) => {
    const [data, setData] = useState<AdditionalDataType[]>([])

    useEffect(() => {
        if (integration?.additionalData) {
            setData(Object.keys(integration.additionalData).map((key) => ({ label: key, value: integration.additionalData![key] })))
        }
    }, [integration])

    if (!integration?.externalId) return <></>

    return (
        <div className="d-flex flex-column w-100 gap-2">
            <div>Identifiant externe : {integration.externalId}</div>
            {data.length > 0 &&
                <>
                    <div>Données additionnelles :</div>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <td className="w-25">Clé</td>
                                <td className="w-75">Valeur</td>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, i) => (
                                <tr key={i}>
                                    <td>{item.label}</td>
                                    <td>{item.value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            }
        </div>
    )
}