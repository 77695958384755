import { useEffect, useState } from "react";

import React from "react";

// react-bootstrap components
import {
  Container,
} from "react-bootstrap";


export function AdminEvents() {

  return (
    <>
      <Container fluid>
        Non Implémenté
      </Container>
    </>)
}