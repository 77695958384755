import { CommandError, CommandErrorDetail, ValidationErrorCode } from "../services"
import { useTranslation } from 'react-i18next'

export const useCommandErrorMessage = () => {

    const getCommandErrorDetailMessage = useCommandErrorDetailMessage()

    const getErrorMessage = (error: CommandError): any => {
        if (error.errors!.length > 0) {
            const detail = error.errors![0]
            return getCommandErrorDetailMessage(detail)
        }
    }

    return (error: CommandError): string => getErrorMessage(error)
}

export const useCommandErrorDetailMessage = () => {

    const { t, i18n } = useTranslation()

    const getErrorMessage = (detail: CommandErrorDetail): string => {
        if (detail.errorMessage && i18n.exists(detail.errorMessage)) {
            //Traduction présente pour le message
            return t(detail.errorMessage, detail.errorStates ?? {})
        } else if (i18n.exists(detail.errorCode?.toString()!)) {
            //Traduction présente pour le code d'erreur
            return t(detail.errorCode?.toString()!)
        }
        else if (detail.errorMessage) {
            //Pas de traduction pour le message
            return detail.errorMessage
        }
        
        //Erreur inattendue par défaut
        return t(ValidationErrorCode.UnexpectedError)
    }

    return (detail: CommandErrorDetail): string => getErrorMessage(detail)
}