import { useState } from "react"
import { useAuthDataContext } from "../core/AuthDataProvider"
import { ReferentialClient, ReferentialData } from "../services"

export type SkillsResult = {
    loading: boolean
    search: (q: string) => Promise<void>
    results: ReferentialData[]
}

export const useSkills = (): SkillsResult => {
    useAuthDataContext()
    const _client = new ReferentialClient()
    const [loading, toggleLoading] = useState(false)
    const [results, setResults] = useState<ReferentialData[]>([])

    const search = async (q: string) => {
        try {
            toggleLoading(true)
            setResults(await _client.getCompetencies(q, 0))
        } catch (error) {

        }
        finally {
            toggleLoading(false)
        }
    }

    return {
        loading: loading,
        search: search,
        results: results
    }
}