
import React, { useEffect, useState } from "react";
import { Button, Container, Nav, Navbar, NavDropdown, Offcanvas } from "react-bootstrap";
import { useAuthDataContext } from "../../core/AuthDataProvider";
import logo from "../../assets/img/v3white.webp"
import { ChangePasswordModal } from "../ChangePasswordModal";

export function SupportNavBar() {
  const { userData, onLogout } = useAuthDataContext();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)

  function changePassword(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
    e.preventDefault()
    setShowChangePasswordModal(true)
  }

  return (
    <>
      {showChangePasswordModal && <ChangePasswordModal onHide={() => setShowChangePasswordModal(false)} />}
      <Navbar collapseOnSelect bg="primary" sticky="top" variant="dark" expand="{false}">
        <Container fluid>
          <Navbar.Brand href="#home">
            <img
              width="69px"
              height="52px"

              className="d-inline-block align-top"
              alt="Support Cyberjobs"
              src={logo}
            />{' '}<span className="text-center me-auto">Plateforme Administration Cyberjobs</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar" />

          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">{userData?.firstName ?? ""}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="d-flex flex-column align-items-stretch w-100 h-100">
                <div className="flex-grow-1 p-3">
                  <Nav className="">
                    <a href="#" onClick={(e) => changePassword(e)}>Modifier mon mot de passe</a>
                  </Nav>
                </div>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Button onClick={onLogout}>Se déconnecter</Button>
                </Nav>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  )
}