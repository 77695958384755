import { useEffect, useState } from "react"
import { Card, Spinner } from "react-bootstrap"
import { useAuthDataContext } from "../../core/AuthDataProvider"
import { AdminJobClient, AdminJobModel, CommandError, CommandErrorDetail, ValidationErrorCode } from "../../services"
import { EditableJobCardForm } from "./EditableJobCardForm"
import './EditableJobCard.scss'

type EditableJobCardProps = {
    jobId: string
    hide: (refresh?: boolean) => void
}

export const EditableJobCard = ({ jobId, hide }: EditableJobCardProps) => {
    useAuthDataContext()
    const client = new AdminJobClient()

    const [loading, toggleLoading] = useState(true)
    const [job, setJob] = useState<AdminJobModel>()
    const [commandError, setCommandError] = useState<CommandError | null>(null)

    useEffect(() => {
        const loadJob = async () => {
            try {
                setCommandError(null)
                toggleLoading(true)
                setJob(undefined)
                const response = await client.getJob(jobId)
                if (response.job) {
                    setJob(response.job)
                }
            } catch (error: any) {
                if (error instanceof CommandError) {
                    setCommandError(error)
                } else {
                    setCommandError(new CommandError({ errors: [new CommandErrorDetail({ errorCode: ValidationErrorCode.UnexpectedError, errorMessage: error?.toString() })] }))
                }
            } finally {
                toggleLoading(false)
            }
        }

        loadJob()
    }, [jobId])

    if (loading) {
        return (
            <Card className="mb-2 mt-2">
                <Card.Body>
                    <div className="spinner-container"><Spinner animation="border" variant="primary" role="status" /></div>
                </Card.Body>
            </Card>
        )
    }

    if (commandError) {
        return (
            <Card className="mb-2 mt-2">
                <Card.Body>
                    <div className="error">{commandError.errors![0].errorCode}</div>
                </Card.Body>
            </Card>
        )
    }

    return <EditableJobCardForm job={job!} hide={hide} />
}