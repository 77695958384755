import { useEffect, useState } from "react";
import { Login } from "../components/Connexion/login";
import { UserClient } from "../services";
import React from "react";

// react-bootstrap components
import {

  Card,

  Container,
  Row,
  Col,

} from "react-bootstrap";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export function Home() {

  return (
    <>
      <Container fluid>
        <Row>
         Tableau de bord en cours de création ...
        </Row>
      </Container>
    </>)
}