import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Badge, Button, Card, Col, OverlayTrigger, Popover, Row, Stack } from "react-bootstrap";
import { AdminCandidateResponseModel, CandidateTodoAdminState, CVStatus, IAdminCandidateResponseModel, TodoAdminResponseModel, WebProfileType } from "../../services";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from "react-router";
import { DateTime } from "luxon"
import { faBan, faCircle, faFilePdf, faSearch, faTimes, faTrash, faEye, faEyeSlash, faE, faBell, faCheck, faCheckSquare, faSquare, faFileCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { ShowCVCandidate } from "./showcv";
import { candidateModal } from "./index"
import { useReferentielDataContext } from "../../core/ReferentielProvider";

const DEFAULT_SOURCE_KEY = 'local'
const DEFAULT_SOURCE_NAME = "Cyberjobs interne"

export interface ICandidateProps {
    candidate: AdminCandidateResponseModel
    onSelect: () => void;
    selected?: boolean;
    onDelete: () => void;
    setCandidateModal: React.Dispatch<React.SetStateAction<candidateModal>>;
}

export function PersonalInformation(candidate: IAdminCandidateResponseModel) {
    const { publishedDate, validatedDate, registeredDate, lastUpdate, japdDone, actualRegion, telephone, email, lastConnectionDate } = candidate
    const [sources, setSources] = useState<string[]>([DEFAULT_SOURCE_NAME])

    useEffect(() => {
        const sources = new Array<string>()
        if (candidate?.sources && candidate.sources.length > 0) {
            if (candidate.sources.some(x => x.key === DEFAULT_SOURCE_KEY)) {
                sources.push(DEFAULT_SOURCE_NAME)
            }

            sources.push(...candidate.sources
                .filter(x => x.key !== DEFAULT_SOURCE_KEY)
                .sort((a, b) => b.addedAtUtc.getTime() - a.addedAtUtc.getTime())
                .map(x => x.name))
        } else {
            sources.push(DEFAULT_SOURCE_NAME)
        }
        setSources(sources)
    }, [candidate?.sources])

    return (<div className="d-block">
        <Stack direction="vertical" gap={1}>
            <div>
                <b>Recherche :</b>
                <div className="d-inline-block ps-1"><DisplayCandidateStatus candidate={candidate} /></div>
            </div>
            <div>
                <b>Source :</b>&nbsp;
                {sources[0]}
                {sources.length > 1 && <span className="badge rounded-pill bg-success ms-1" title={sources.join('\n')}>{` + ${sources.length}`}</span>}
            </div>

            <div> <b>Email :</b> {email} <b>  Telephone :</b> {telephone}</div>
            <div> <b> Région :</b> {actualRegion ?? "NC"}</div>
            <div> <b>Inscrit le  :</b>  {registeredDate && <>{DateTime.fromJSDate(registeredDate).toFormat("dd/MM/yyyy")}</>}
                <>
                    {registeredDate && lastUpdate && (
                        DateTime.fromJSDate(registeredDate).toFormat("yyyyMMdd") == DateTime.fromJSDate(lastUpdate).toFormat("yyyyMMdd")) &&
                        <Badge className="ms-1" pill bg={"success"}>New</Badge>
                    }</>
            </div>
            <div> <b>MAJ Candidat  :</b> {lastUpdate && <Badge bg="danger">{DateTime.fromJSDate(lastUpdate).toFormat("dd/MM/yyyy")}</Badge>}

                <>
                    {lastUpdate && validatedDate && (
                        DateTime.fromJSDate(validatedDate) < DateTime.fromJSDate(lastUpdate)) &&
                        <Badge pill bg={"warning"}>Mise à jour</Badge>
                    }</></div>
            <div> <b>Dernière connexion :</b>
                {lastConnectionDate && <>{DateTime.fromJSDate(lastConnectionDate).toFormat("dd/MM/yyyy")}</>}
            </div>
            {publishedDate &&
                <div> <b>Publié le  :</b>{DateTime.fromJSDate(publishedDate).toFormat("dd/MM/yyyy")}</div>
            }
            <div>
                {japdDone != undefined
                    && <span title="japd" className={"mx-1 badge" + (japdDone === true ? " bg-success" : " bg-disabled")} >JAPD</span>
                }
            </div>
        </Stack >

    </div >)

}

export function CandidateProfile({ workExperienceTime, totalWorkExperienceTime, educationDegree, schools, email, aggregateCompetencies }: IAdminCandidateResponseModel) {
    return (<>
        <Stack direction="vertical" gap={1}>
            <div>
                <b>Expérience cyber :</b> {workExperienceTime}
            </div>
            <div>
                <b>Expérience :</b> {totalWorkExperienceTime}
            </div>
            <div>
                <b>Niv. Formation : </b>{educationDegree}
            </div>

            <div> <b className="d-block">A étudié à </b>
                {
                    schools?.map((c) => <>
                        <Badge bg="none" className="competency mx-1">{c}</Badge>
                    </>)}
            </div>
            <div>
                <b className="d-block">Compétences </b>
                {aggregateCompetencies?.map((c) =>
                    <Badge bg="none" className="competency mx-1">{c}</Badge>
                )}
            </div>
        </Stack>
    </>)
}


export function CandidateSearch({ mobility, mobilityRegions, mobilityContext, contractsType, missionsType, salaryExpected, salaryVisible }: IAdminCandidateResponseModel) {
    return (<>

        <Stack direction="vertical" gap={1}></Stack>
        <div><b>Mobilité :  {mobility ? <>
            {mobility && <> {mobilityRegions?.map((c) => <Badge bg="none" className="region mx-1">{c}</Badge>)}</>}

        </>

            : <Badge bg="disabled">Non</Badge>}</b></div>
        <div>
            <b>Types Contrat :
                <div className="mt-1">
                    {contractsType?.map(s => <Badge className="mx-1">{s}</Badge>)}
                </div>
            </b>
        </div>

        <div>
            <b>Missions :
                <div className="mt-1">
                    {missionsType?.map(s => <Badge className="mx-1">{s}</Badge>)}
                </div>
            </b>
        </div>
        <div><b>Salaire :</b> {salaryExpected}
            {salaryVisible && <FontAwesomeIcon className="ms-2" icon={faEye} />}
            {!salaryVisible && <FontAwesomeIcon className="ms-2" icon={faEyeSlash} />}
        </div>
    </>)
}





export function Candidate({ candidate, onSelect, selected, onDelete, setCandidateModal }: ICandidateProps) {


    const linkedinUrl = useMemo(() => {
        const linkedInProfile = candidate?.webProfiles?.find(x => x.type == WebProfileType.LinkedIn)
        return linkedInProfile?.uri ?? undefined;
    }, [candidate?.webProfiles])

    const handleShowModal = (modalType: "CV" | "timeLine" | "task" | "uploadCV") => {
        candidate.candidateId && setCandidateModal({ candidate, modalType })
    }

    const popover = (lastAdminTodo: TodoAdminResponseModel) => {
        const { adminTodoStateReferentiel } = useReferentielDataContext();
        return (
            <Popover id="popover-basic">
                {lastAdminTodo.dueDate &&
                    <Popover.Header as="h3">{DateTime.fromJSDate(lastAdminTodo.dueDate).toFormat("dd/MM/yyyy")}
                        <span className="ms-3">
                            {adminTodoStateReferentiel.find(d => d.value == CandidateTodoAdminState[(lastAdminTodo?.candidateTodoAdminState ?? CandidateTodoAdminState.TODO)])?.label}
                        </span>

                    </Popover.Header>
                }
                <Popover.Body>
                    {lastAdminTodo.comment}
                </Popover.Body>
            </Popover>
        );
    }
    return (
        <>


            <Card className={"candidate-item mt-1 w-100 " + (selected ? "selected" : "")} style={{ width: '18rem' }}>
                <Card.Header className="candidate-item-header">
                    {candidate.profileUrl &&
                        <Card.Img className="float-start candidate-item-picture me-2" variant="top" src={candidate.profileUrl + "&size=200"} />
                    }
                    <Card.Title className=" w-100">
                        <Stack direction="horizontal" gap={5}>
                            <div>
                                {<small className={"mx-1 candidate-item-cv " + (candidate.isDisabled ? "text-disabled" : candidate.publishedDate ? "text-success" : " text-warning")} >
                                    <FontAwesomeIcon icon={faCircle} />
                                </small>
                                }
                                <b>{candidate.lastName}</b> {candidate.firstName}  {candidate.lastTodoAdmin &&
                                    <OverlayTrigger trigger="hover" placement="right" overlay={popover(candidate.lastTodoAdmin)}>
                                        <Button size="sm" className="mx-1 mt-1" title={candidate.lastTodoAdmin.comment} variant={"warning"} onClick={() => handleShowModal("task")}>
                                            <FontAwesomeIcon className="me-1" icon={faBell} /><span className="d-sm-none d-inline-block">Rappel</span></Button>
                                    </OverlayTrigger>
                                }
                                {!candidate.lastTodoAdmin &&
                                    <Button size="sm" className="mx-1 mt-1" variant={"success"} onClick={() => handleShowModal("task")}>
                                        <FontAwesomeIcon className="me-1" icon={faBell} /><span className="d-sm-none d-inline-block">Rappel</span></Button>
                                }
                                {linkedinUrl && <span className="mx-1 candidate-item-linkedin ">
                                    <FontAwesomeIcon onClick={() => window.open(linkedinUrl ?? "")} icon={faLinkedin} /></span>
                                }
                                {candidate.hasCV && <span onClick={() => handleShowModal("CV")} className={"mx-1 candidate-item-cv " + (candidate.hasCV ? "fa-file-pdf cursor-pointer" : "")} >
                                    <FontAwesomeIcon icon={faFilePdf} />
                                </span>
                                }



                                <br />
                                <h6 className="mt-1">
                                    <Stack direction="horizontal" gap={5}>
                                        <Badge pill bg={candidate.publishedDate ? "success" : "danger"}>{candidate.publishedDate ? (DateTime.now().diff(DateTime.fromJSDate(candidate.publishedDate), "days").days > 60 ? "Communauté" : "CV Thèque") : "Non publié"}</Badge>
                                        {candidate.validatedDate && <Badge className="ms-1" bg="success">Validé le {DateTime.fromJSDate(candidate.validatedDate).toFormat("dd/MM/yyyy")}</Badge>}

                                    </Stack>
                                </h6>
                            </div>
                            <div className="ms-auto">
                                {!candidate.hasCV && <Button className="mx-1 mt-1 position-relative" variant="secondary" onClick={() => handleShowModal("uploadCV")}>
                                    Ajouter CV

                                    <FontAwesomeIcon className="ml-2 icon-check icon-check-left  text-danger " inverse size="2x" icon={faFileCirclePlus} />

                                </Button>
                                }
                                <Button className="mx-1 mt-1 position-relative" variant="secondary" onClick={() => handleShowModal("timeLine")}>
                                    Voir la fiche
                                    {candidate.lastUpdateTimeLine &&
                                        <FontAwesomeIcon className="ml-2  icon-check icon-check-left text-success " inverse size="2x" icon={faCheckSquare} />
                                    }
                                </Button>



                                <Button className="mx-1 mt-1" variant="primary" onClick={(e) => onSelect()}>Valider</Button>
                                <Button className="mx-1 mt-1" variant="danger" onClick={(e) => onDelete()}>
                                    <FontAwesomeIcon className="me-1" icon={faTrash} /><span className="d-sm-none d-inline-block">Supprimer</span></Button>
                            </div>
                        </Stack>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col xxl="4" xl="6" md="6" xs="12">
                            <PersonalInformation {...candidate} />
                        </Col>
                        <Col xxl="4" xl="6" md="6" xs="12">
                            <CandidateProfile  {...candidate}></CandidateProfile>
                        </Col>
                        <Col xxl="4" xl="6" xs="12">
                            <CandidateSearch  {...candidate}></CandidateSearch>
                        </Col>

                    </Row>
                </Card.Body>
            </Card>
        </>)
}

type DisplayCandidateStatusProps = {
    candidate: IAdminCandidateResponseModel
}

const DisplayCandidateStatus = ({ candidate }: DisplayCandidateStatusProps) => {
    const { searchJob, searchFreelance, searchBasicTraining, searchContinuingEducation, activeSearch, cvStatus } = candidate

    if (!searchJob && !searchFreelance && !searchBasicTraining && !searchContinuingEducation) return <Badge className="bg-primary">Non</Badge>

    return (
        <>
            {searchJob &&
                <>
                    {
                        activeSearch === true
                            ? <Badge className="bg-success"><FontAwesomeIcon className="text-white me-1" icon={faSearch} />Active</Badge>
                            : <Badge className="bg-warning"><FontAwesomeIcon className="text-white me-1" icon={faSearch} />Passive</Badge>
                    }
                    {
                        cvStatus == 0 && <Badge title="CV A jour" className="bg-success ms-1">CV à jour</Badge>
                    }
                    {
                        cvStatus == 1 && <Badge title="CV Pas à jour" className="bg-warning ms-1">CV</Badge>
                    }
                    {
                        cvStatus == 2 && <Badge title="CV Plus tard" className="bg-danger ms-1">CV</Badge>
                    }
                </>
            }
            {searchFreelance && <Badge className="bg-sucess ms-1">Freelance</Badge>}
            {searchBasicTraining && <Badge className="bg-sucess ms-1">Formation initiale</Badge>}
            {searchContinuingEducation && <Badge className="bg-sucess ms-1">Formation continue</Badge>}
        </>
    )
}