import { faEdit, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { AdminCandidateResponseModel } from "../../services";
import { EditCandidateInfosModelContext } from "./editCandidateInfosModelContext";

type HeaderProps = {
  candidate: AdminCandidateResponseModel | null;
  handleHideModal: () => void;
};
export type HeaderListProps = {
  data: string[] | undefined;
  title: string;
  color: "blue" | "red" | "green" | "yellow";
};
type CandidateProps = { candidate: AdminCandidateResponseModel | null };

export const Header = ({ candidate, handleHideModal }: HeaderProps) => {
  const { editMode } = useContext(EditCandidateInfosModelContext)

  const formatPhoneNumber = () => {
    if (candidate?.telephone?.includes(" ")) return;
    const numberSplited = candidate?.telephone?.split("");

    numberSplited?.forEach((number, i) => {
      if (i * 3 > numberSplited.length - 1) return;
      numberSplited.splice(i * 3, 0, " ");
    });

    return numberSplited;
  };

  return (
    <>
      <HideModalButton handleHideModal={handleHideModal} />
      <h3>{`Fiche candidat ${editMode ? " - Edition" : ""}`}</h3>
      <div className="candidate-modal-header">
        <div className="candidate-modal-header-left">
          <ProfilePicture candidate={candidate} />
          <div className="candidate-modal-header-left-top">
            <h2>{candidate?.firstName + " " + candidate?.lastName}</h2>
            {candidate?.email && <span>{candidate?.email}</span>}
            {candidate?.telephone && <span>{formatPhoneNumber()}</span>}
          </div>
        </div>
        <Right candidate={candidate} />
      </div>
    </>
  );
};

const ProfilePicture = ({ candidate }: CandidateProps) => {
  return (
    <div>
      <div className="candidate-modal-header-PP">
        {(candidate?.firstName as string)[0].toUpperCase() +
          "" +
          (candidate?.lastName as string)[0].toUpperCase()}
      </div>
    </div>
  );
};

type Visibility = "collapse" | "hidden" | "visible";

const Right = ({ candidate }: CandidateProps) => {

  const { editMode, toggleEditMode } = useContext(EditCandidateInfosModelContext)
  const [visibility, setVisibility] = useState<Visibility>("visible")

  useEffect(() => {
    if (
      !candidate?.competencies?.length &&
      !candidate?.schools?.length &&
      !candidate?.contractsType?.length &&
      !candidate?.missionsType?.length
    ) {
      setVisibility("hidden")
    }
    else {
      setVisibility("visible")
    }
  }, [candidate?.competencies, candidate?.schools, candidate?.contractsType, candidate?.missionsType])

  return (
    <div className="candidate-modal-header-right">
      {!editMode &&
        <div className="candidate-modal-header-right-edit">
          <FontAwesomeIcon icon={faEdit} onClick={() => toggleEditMode(!editMode)} />
        </div>
      }
      <div className="candidate-modal-header-right-wrapper" style={{ visibility: visibility }}>
        <List title="Compétence(s)" data={candidate?.competencies} color="blue" />
        <List title="École(s)" data={candidate?.schools} color="red" />
        <List
          title="Type(s) de contrat(s) recherché(s)"
          data={candidate?.contractsType}
          color="green"
        />
        <List
          title="Type(s) de métier(s) recherché(s)"
          data={candidate?.missionsType}
          color="yellow"
        />
      </div>
    </div>
  );
};

export const List = ({ data, title, color }: HeaderListProps) => {
  const isEmpty = !data?.some((item) => item !== "" && item !== " ");
  if (!data || isEmpty) return null;

  return (
    <div style={{ display: "flex", flexDirection: "column", maxWidth: "100%" }}>
      <h6>{title}</h6>
      <ul>
        {data?.map((item, i) => (
          <li key={i} className={color}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

const HideModalButton = ({
  handleHideModal,
}: {
  handleHideModal: () => void;
}) => {
  return (
    <div className="candidate-modal-hide-button" onClick={handleHideModal}>
      <FontAwesomeIcon icon={faXmark} />
    </div>
  );
};
