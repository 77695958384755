import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AdminEventClient, CountryResponseModel, EventModel, GetSelectEventFilterResponse, JobDirectoryClient, ReferentialClient, ReferentialData } from "../services";
import { useAuthDataContext } from "./AuthDataProvider";

export type ReferentielContextType = {
    contractsType: ReferentialData[]
    contractsTypeWithoutAvailability: ReferentialData[]
    regions: CountryResponseModel[]
    salaryOptions: ReferentialData[]
    schoolsOptions: ReferentialData[]
    eventsOptions: ReferentialData[]
    experiencesOptions: ReferentialData[]
    jobTypesOptions: ReferentialData[],
    adminTodoStateReferentiel: ReferentialData[],
    jobsSalary: ReferentialData[]
    jobsContractsType: ReferentialData[]
    jobsRegions: ReferentialData[]
    schoolLevels: ReferentialData[]
    searchCompetencies: (q: string) => Promise<ReferentialData[]>
}
export const ReferentielContext =
    createContext<ReferentielContextType>(
        undefined as unknown as ReferentielContextType
    );


export interface ReferentialProviderProps {

}

export const ReferentialProvider = (props: ReferentialProviderProps) => {
    const { isAuthenticated } = useAuthDataContext();
    const [contractsType, setContractsType] = useState<ReferentialData[]>([]);
    const [contractsTypeWithoutAvailability, setContractsTypeWithoutAvailability] = useState<ReferentialData[]>([]);
    const [jobsContractsType, setJobsContractsType] = useState<ReferentialData[]>([])
    const [regions, setRegions] = useState<CountryResponseModel[]>([]);
    const [jobsRegions, setJobsRegions] = useState<ReferentialData[]>([]);
    const [events, setEvents] = useState<ReferentialData[]>([]);
    const [salary, setSalary] = useState<ReferentialData[]>([]);
    const [jobsSalary, setJobsSalary] = useState<ReferentialData[]>([]);
    const [schools, setSchools] = useState<ReferentialData[]>([]);
    const [experiencesOptions, setExperiencesOptions] = useState<ReferentialData[]>([]);
    const [jobTypesOptions, setJobTypesOptions] = useState<ReferentialData[]>([]);
    const [adminTodoStateReferentiel, setAdminTodoStateReferentiel] = useState<ReferentialData[]>([]);
    const [schoolLevels, setSchoolLevels] = useState<ReferentialData[]>([]);
    const _client = new ReferentialClient()
    const _eventClient = new AdminEventClient()
    useEffect(() => {
        if (isAuthenticated) {
            loadOption(() => _client.getSalaryCandidateOptions(), setSalary)
            loadOption(() => _client.getJobsSalary(), setJobsSalary)
            loadOption(() => _client.getSearchTypes(), setContractsType);
            loadOption(() => _client.getContractTypesWithoutAvailability(), setContractsTypeWithoutAvailability);
            loadOption(() => _client.getMissionTypes(), setJobsContractsType);
            loadOption(() => _client.getMobilityRegions(), setRegions);
            loadOption(() => _client.getRegions(), setJobsRegions);
            loadOption(async () => {
                var events = (await _eventClient.getEventsOptionFilter())?.events;
                return events?.map(e => ReferentialData.fromJS({ "label": e.name, "value": e.id })) ?? [];
            }, setEvents)

            loadOption(() => _client.getSchoolsOptions(), setSchools);
            loadOption(() => _client.getExperiencesOptions(), setExperiencesOptions);
            loadOption(() => _client.getJobsTypesGroupByDepartment(), setJobTypesOptions)
            loadOption(() => _client.getTodoAdminStateReferential(), setAdminTodoStateReferentiel)
            loadOption(() => _client.schoolLevels(), setSchoolLevels)
        }
    }, [isAuthenticated])

    const loadOption = async <T extends any>(getter: () => Promise<T[]>, setter: React.Dispatch<React.SetStateAction<T[]>>) => {
        try {
            setter([...await getter()])
        } catch (error) {
            console.error(error)
        }
    }

    const searchCompetencies = (q: string) => {
        return _client.getCompetencies(q)
    }

    const refContext: ReferentielContextType = useMemo(() => {
        return {
            adminTodoStateReferentiel: adminTodoStateReferentiel,
            jobTypesOptions: jobTypesOptions,
            contractsType: contractsType,
            contractsTypeWithoutAvailability: contractsTypeWithoutAvailability,
            jobsContractsType: jobsContractsType,
            experiencesOptions: experiencesOptions,
            regions: regions,
            salaryOptions: salary,
            schoolsOptions: schools,
            eventsOptions: events,
            jobsSalary: jobsSalary,
            jobsRegions: jobsRegions,
            searchCompetencies: searchCompetencies,
            schoolLevels: schoolLevels
        }
    }, [regions, contractsType, contractsTypeWithoutAvailability, jobTypesOptions, salary, schools, experiencesOptions, adminTodoStateReferentiel, events, jobsSalary, schoolLevels]);

    return <ReferentielContext.Provider value={refContext} {...props} />;

};

export const useReferentielDataContext = () => useContext(ReferentielContext);

