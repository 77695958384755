import * as React from "react";
import { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useAuthDataContext } from "../../core/AuthDataProvider";
import { CandidateClient, CandidateInterestsCommand } from "../../services";





export interface ICandidateInterestProps {
    candidateId: string;

}

let json_form = [
    {
        grouplabel: "Se former",
        values: [
            {
                label: "Me former en cybersécurité",
            },
            {
                label: "Me reconvertir en cybersécurité",
            },
        ],
    },
    {
        grouplabel: "Débuter sa carrière",
        values: [
            {
                label: "Trouver un stage ou une alternance",
            },
            {
                label: "Décrocher mon premier emploi",
            },
        ],
    },
    {
        grouplabel: "Gérer sa carrière",
        values: [
            {
                label: "Développer mon réseau",
            },
            {
                label: "Booster ma carrière",
            },
            {
                label: "Décrocher un nouvel emploi",
            },
            {
                label: "Rester en veille de nouvelles opportunités",
            },
        ],
    },
];

export default function CandidateInterest({
    candidateId
}: ICandidateInterestProps) {
    var { loading } = useAuthDataContext();

    var _client = new CandidateClient();

    const allInterests = () => {
        const interestsList: any = [];
        json_form.forEach((item) =>
            item.values.forEach((value) => interestsList.push(value.label))
        );
        return interestsList;
    };
    const [interests, setinterests] = useState<string[]>([]);

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);


    useEffect(() => {
        setIsLoading(true);
        if (candidateId) {
            _client.getCandidateInterests(candidateId).then((_) => {

                if (_.interests) setinterests(_.interests);
                setIsLoading(false);
            });
        }
    }, []);

    const handleChange = (value: string, checked: boolean) => {
        var index = interests?.indexOf(value);
        var _ = [...interests] ?? [];
        if (checked && index == -1) {
            _.push(value);
        }
        if (!checked && index > -1) {
            _.splice(index, 1);
        }
        setinterests(_);
    };

    const saveInterests = async () => {
        setIsLoadingSubmit(true);

        _client
            .saveCandidateInterests(
                candidateId,
                CandidateInterestsCommand.fromJS({ interests: interests })
            )
            .then(() => {
                setIsLoadingSubmit(false);
            });
    };

    return (<>
        <div className="candidate-interests-header text-center">
            <h2>Centres d'intérêt du candidat</h2>


        </div>


        {!isLoading && (
            <ul className="candidate-interests-form w-100">
                {json_form.map((s, i) => (
                    <li key={i}>
                        <h4>{s.grouplabel}</h4>
                        <ul className="mt-1 mb-3">
                            {s.values.map((v, i) => (
                                <li className="ms-3" key={i}>

                                    <Form.Check
                                        type="switch"
                                        label={v.label}
                                        id={v.label}
                                        checked={interests.indexOf(v.label) >= 0}
                                        onChange={(e) => {
                                            handleChange(v.label, e.target.checked);
                                        }}
                                    />

                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>
        )}



        <div className="candidate-interests-actions text-center">
            <Button className="mx-1" onClick={saveInterests} variant="secondary">Sauvegarger les centres d'intérêt</Button>

        </div>
    </>
    );
}

function choice({ label, checked, onChange }: any) {
    return <></>;
}
