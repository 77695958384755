import { FieldPath, FieldValues, RegisterOptions } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { useAuthDataContext } from "../core/AuthDataProvider";
import { UserClient } from "../services";

const PASSWORD_VALIDATION_RULES = {
    MIN_LENGTH: 8
}

type ReturnFunctionType<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = () => RegisterOptions<TFieldValues, TFieldName>
type FunctionType<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = () => ReturnFunctionType<TFieldValues, TFieldName>;

export const usePasswordValidation: FunctionType<FieldValues, FieldPath<FieldValues>> = () => {
    useAuthDataContext();
    const userClient = new UserClient()
    const { t } = useTranslation()

    const validate = async (password: any) => {
        let isValid = false

        //Vérifie la présence d'une majuscule
        if (!password.match(/[A-Z]/)) {
            return t('PasswordValidation.Uppercase')
        }

        //Vérifie la présence d'une minuscule
        if (!password.match(/[a-z]/)) {
            return t('PasswordValidation.Lowercase')
        }

        //Vérifie la présence d'un caractère spécial
        // if (!password.match(/\W|_/g)) {
        //     return t('PasswordValidation.SpecialChar')
        // }

        //Ultime vérification côté API
        try {
            const response = await userClient.checkPasswordValidity(password)
            isValid = response.isValid!
            console.log('check', isValid)
        } catch (error) {
            console.error(error)
            isValid = false
        }

        return isValid || (t('PasswordValidation.Invalid') as string)
    }

    return () => ({
        required: t('PasswordValidation.Mandatory') as string,
        minLength: {
            value: PASSWORD_VALIDATION_RULES.MIN_LENGTH,
            message: t('PasswordValidation.MinLength', { minLength: PASSWORD_VALIDATION_RULES.MIN_LENGTH })
        },
        validate: async (password) => await validate(password)
    })
} 