import { ISheetCandidateModel } from "../../services";
import { IEditableTimeLineCardModel } from "./IEditableTimeLineCardModel";

export const groupedTimeLine = (data: IEditableTimeLineCardModel[]) => {
  const timeLineGroups: IEditableTimeLineCardModel[][] = [];

  const sortStore = (store: Map<string, IEditableTimeLineCardModel[]>) => {

    const newStore = new Map<string, IEditableTimeLineCardModel[]>()
    const keys: string[] = []

    store.forEach((_, key) => {
      keys.push(key)
    })

    keys
      .sort((a: any, b: any) => {
        a = parseFloat(a)
        b = parseFloat(b)
        a = Number.isNaN(a) ? 0 : a
        b = Number.isNaN(b) ? 0 : b
        return b - a  //Reverse : du plus récent au plus ancien
      })
      .map((item) => {
        newStore.set(item, store.get(item)!)
      })


    return newStore
  }

  const groupByYear = sortStore(data?.reduce((store, item) => {
    var key = item.year ?? ""
    if (!store.has(key)) {
      store.set(key, [item])
    } else {
      store.get(key)?.push(item)
    }
    return store
  }, new Map<string, IEditableTimeLineCardModel[]>()))

  groupByYear.forEach((list) => {
    timeLineGroups.push(list);
  });

  return timeLineGroups;
};

export const toTimeLine = (candidateData: ISheetCandidateModel | null): IEditableTimeLineCardModel[] | undefined => {
  let counter = 1
  if (candidateData?.timeLine !== undefined) {
    return [...candidateData.timeLine.map((item) => { return { id: counter++, ...item } })]
  } else {
    if (!candidateData?.educations || !candidateData?.employments) return undefined;
    const educationsCopy = candidateData.educations.map((education) => {
      return {
        id: counter++,
        year: education?.start?.slice(0, 4) ?? "",
        yearEnd: education?.end?.slice(0, 4) ?? "",
        organization: education?.institution,
        description: education?.description,
        type: "education",
      } as IEditableTimeLineCardModel;
    });
    const jobsCopy = candidateData.employments.map((employement) => {
      return {
        id: counter++,
        title: employement?.title,
        year: employement?.start?.slice(0, 4) ?? "",
        yearEnd: employement?.end?.slice(0, 4) ?? "",
        current: employement?.isCurrent,
        organization: employement?.organization,
        description: employement?.description,
        type: "job",
      } as IEditableTimeLineCardModel;
    });
    return [...educationsCopy, ...jobsCopy];
  }
};
