import React, { useContext, useEffect, useState } from "react";
import { AdminCandidateClient, ISheetCandidateModel, ITimeLineCardModel, TimeLineCardModel, UpdateTimeLineCommand } from "../../services";
import { EditCandidateInfosModelContext } from "./editCandidateInfosModelContext";
import { groupedTimeLine, toTimeLine } from "./groupedTimeLine";
import { TimeLine } from "./TimeLine";
import { IEditableTimeLineCardModel } from "./IEditableTimeLineCardModel";
import { DraggableDirection, DraggableTimeLineContext, TimeLineContext } from "./TimeLineContext";

type TimeLineEditorProps = {
    candidateId: string | null
    candidateData: ISheetCandidateModel | null
    isLoading: boolean
}

type TimeLineHistoryModel = {
    openedCards: number[]
    timeline: IEditableTimeLineCardModel[]
}

export const TimeLineEditor = ({ candidateId, candidateData, isLoading }: TimeLineEditorProps) => {

    const { editMode, toggleEditMode } = useContext(EditCandidateInfosModelContext)
    const [timeline, setTimeLine] = useState<IEditableTimeLineCardModel[]>([])
    const [initialTimeline, setInitialTimeLine] = useState<IEditableTimeLineCardModel[]>([])
    const [editableTimeline, setEditableTimeLine] = useState<IEditableTimeLineCardModel[]>([])
    const [openedCards, setOpenedCards] = useState<number[]>([])
    const [timelineHistory, setTimeLineHistory] = useState<TimeLineHistoryModel[]>([])
    const [redoTimelineHistory, setRedoTimeLineHistory] = useState<TimeLineHistoryModel[]>([])
    const [nextId, setNextId] = useState(0)
    const [hasChanges, setHasChanges] = useState(false)
    const [saveDisabled, setSaveDisabled] = useState(false)
    const [canUndo, setCanUndo] = useState(false)
    const [canRedo, setCanRedo] = useState(false)

    const [draggableItem, setDraggableItem] = useState<IEditableTimeLineCardModel | undefined>()
    const [isOver, setIsOver] = useState(false)
    const [direction, setDirection] = useState<DraggableDirection | undefined>()
    const [previousX, setPreviousX] = useState<number | undefined>()
    const [posY, setPosY] = useState<number | undefined>()

    const adminCandidateClient = new AdminCandidateClient()

    useEffect(() => {
        if (posY !== undefined) {
            if (previousX !== undefined) {
                setDirection(previousX > posY ? DraggableDirection.Up : DraggableDirection.Down)
            }
            setPreviousX(posY)
        }
    }, [posY])

    /**
     * Chargement initial
     */
    useEffect(() => {
        if (candidateData != null) {
            const candidateTimeline = toTimeLine(candidateData)!
            if (candidateTimeline !== undefined) {
                calculateNextId(candidateTimeline)
                setTimeLine(candidateTimeline)
                setInitialTimeLine(candidateTimeline)
                setOpenedCards([])
            }
        }
    }, [candidateData?.educations, candidateData?.employments, candidateData?.timeLine])

    /**
     * Annulation de l'édition
     */
    useEffect(() => {
        if (editMode === false) {
            calculateNextId(initialTimeline)
            setTimeLine([...initialTimeline])
            setOpenedCards([])
            setTimeLineHistory([])
            setRedoTimeLineHistory([])
        }

    }, [editMode])

    /**
     * Initialisation de la timeline éditable
     */
    useEffect(() => {
        if (editMode === true) {
            setEditableTimeLine([...timeline])
        }
    }, [editMode])

    useEffect(() => {
        setHasChanges(timelineHistory.length > 0)
        setCanUndo(timelineHistory.length > 0)
    }, [timelineHistory])

    useEffect(() => {
        setCanRedo(redoTimelineHistory.length > 0)
    }, [redoTimelineHistory])

    /**
     * Ajoute les dernières modifications dans l'historique
     */
    const pushToHistory = (timeline: IEditableTimeLineCardModel[], openedCards: number[], reset: boolean) => {
        if (reset) {
            setTimeLineHistory([])
        }
        setTimeLineHistory([...timelineHistory, { openedCards: openedCards, timeline: timeline }])
    }

    /**
     * Désactive la sauvegarde si des cartes sont ouvertes en modification ou si l'historique ne contient pas de modifications
     */
    useEffect(() => {
        setSaveDisabled(openedCards.length > 0 || timelineHistory.length == 0)
    }, [openedCards, timelineHistory])

    const calculateNextId = (timeline: IEditableTimeLineCardModel[]) => {
        if (timeline == undefined || timeline.length == 0) {
            setNextId(1)
        } else {
            setNextId(Math.max(...timeline.map((x) => x.id)) + 1)
        }
    }

    /**
     * Ajoute une carte
     * @description la carte est ajoutée dans la timeline éditable 
     * @param afterCard 
     */
    const onAddCard = (card: ITimeLineCardModel, sibblingCardId: number | null = null, after: boolean) => {
        const model = {
            id: nextId,
            ...card
        }

        let index = 0
        if (sibblingCardId !== null) {
            index = editableTimeline.findIndex(x => x.id == sibblingCardId)
        }

        setOpenedCards([...openedCards, model.id])
        setEditableTimeLine(addItem(model, editableTimeline, index, after))
        setNextId(nextId + 1)
    }

    /**
     * Ferme une carte
     * @param cardId 
     */
    const onCloseCard = (cardId: number) => {
        const index = openedCards.findIndex(x => x == cardId)
        if (index > -1) {
            setOpenedCards([
                ...openedCards.slice(0, index),
                ...openedCards.slice(index + 1)
            ])
        }
    }

    /**
     * Annule les modifications sur une carte
     * @param card 
     */
    const onCancelCardEdition = (card: IEditableTimeLineCardModel) => {
        onCloseCard(card.id)
        const index = timeline.findIndex(x => x.id == card.id)
        if (index > -1) {
            const original = timeline.at(index)!
            setEditableTimeLine(replaceItem(original, editableTimeline))
        }
        else {
            setEditableTimeLine(removeItem(card, editableTimeline))
        }
    }

    /**
     * Met à jour une carte
     * @param card 
     */
    const onUpdateCard = (card: IEditableTimeLineCardModel, draft: boolean) => {
        setEditableTimeLine(replaceItem(card, editableTimeline))

        if (!draft) {
            const index = timeline.findIndex(x => x.id == card.id)
            if (index > -1) {
                setTimeLine(replaceItem(card, timeline))
            }
            else {
                //ajout
                let editableIndex = editableTimeline.findIndex(x => x.id == card.id)
                setTimeLine(addItem(card, timeline, editableIndex <= 0 ? -1 : editableIndex))
            }
            pushToHistory(timeline, openedCards, false)
        }
    }

    /**
     * Supprime une carte
     * @param card 
     */
    const onDeleteCard = (card: IEditableTimeLineCardModel) => {
        setTimeLine(removeItem(card, timeline))
        setEditableTimeLine(removeItem(card, editableTimeline))
        pushToHistory(timeline, openedCards, false)
        onCloseCard(card.id)
    }

    /**
     * Déplace une carte
     */
    const onMoveCard = (card: IEditableTimeLineCardModel, destinationCard: IEditableTimeLineCardModel | null, after: boolean) => {
        let position = 0
        if (destinationCard != null) {
            position = editableTimeline.findIndex(x => x.id == destinationCard.id)
        }
        else if (card.year !== undefined) {
            position = editableTimeline.findIndex(x => x.year == card.year)
        }

        setEditableTimeLine(
            addItem(card,
                removeItem(card, editableTimeline), position, after))
    }

    const addItem = (card: IEditableTimeLineCardModel, list: IEditableTimeLineCardModel[], index: number, after: boolean = true) => {
        if (after) {
            return [
                ...list.slice(0, index + 1),
                card,
                ...list.slice(index + 1)
            ]
        }
        else {
            return [
                ...list.slice(0, index),
                card,
                ...list.slice(index)
            ]
        }
    }

    const removeItem = (card: IEditableTimeLineCardModel, list: IEditableTimeLineCardModel[]) => {
        const index = list.findIndex(x => x.id == card.id)
        if (index > -1) {
            return [
                ...list.slice(0, index),
                ...list.slice(index + 1)
            ]
        }
        return list
    }

    const replaceItem = (card: IEditableTimeLineCardModel, list: IEditableTimeLineCardModel[]) => {
        const index = list.findIndex(x => x.id == card.id)
        if (index > -1) {
            return [
                ...list.slice(0, index),
                card,
                ...list.slice(index + 1)
            ]
        }
        return list
    }

    /**
     * Sauvegarde les modifications
     */
    const onSaveChanges = () => {
        const command = new UpdateTimeLineCommand({
            timeLine: timeline.map((item) => new TimeLineCardModel(item))
        })

        adminCandidateClient.updateTimeLine(candidateId, command)
            .then(() => {
                setInitialTimeLine(timeline)
                toggleEditMode(false)
            })
            .catch((error) => {
                alert("Une erreur est survenue lors de l'enregistrement de la timeline")
            })
    }

    const onUndo = () => {
        if (canUndo) {
            const latest = timelineHistory[timelineHistory.length - 1]
            setRedoTimeLineHistory([{ openedCards: openedCards, timeline: editableTimeline }, ...redoTimelineHistory])
            setTimeLine([...latest.timeline])
            setEditableTimeLine([...latest.timeline])
            setOpenedCards([...latest.openedCards])
            setTimeLineHistory([...timelineHistory.slice(0, timelineHistory.length - 1)])
        }
    }

    const onRedo = () => {
        if (canRedo) {
            const first = redoTimelineHistory[0]
            pushToHistory(editableTimeline, openedCards, false)
            setTimeLine([...first.timeline])
            setEditableTimeLine([...first.timeline])
            setOpenedCards([...first.openedCards])
            setRedoTimeLineHistory([...redoTimelineHistory.slice(1)])
        }
    }

    return (
        <TimeLineContext.Provider value={
            {
                openedCards: openedCards,
                closeCard: onCloseCard,
                cancelCardEdition: onCancelCardEdition,
                addCard: onAddCard,
                updateCard: onUpdateCard,
                deleteCard: onDeleteCard,
                saveChanges: onSaveChanges,
                hasChanges: hasChanges,
                saveDisabled: saveDisabled,
                undo: onUndo,
                redo: onRedo,
                canUndo: canUndo,
                canRedo: canRedo,
                moveCard: onMoveCard
            }}>
            <DraggableTimeLineContext.Provider value={{
                draggableItem: draggableItem,
                setDraggableItem: setDraggableItem,
                direction: direction,
                setDirection: setDirection,
                isOver: isOver,
                setIsOver: setIsOver,
                setPosY: setPosY
            }}>
                <TimeLine isLoading={isLoading} data={groupedTimeLine(editMode ? editableTimeline : timeline)} />
            </DraggableTimeLineContext.Provider>
        </TimeLineContext.Provider>
    )
}