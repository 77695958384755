import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { Config } from './core/Config';

i18n.use(HttpApi).init({
  debug: true,
  fallbackLng: 'fr-FR',
  backend: {
    crossDomain: true,
    loadPath: `${Config.api_url}/api/traduction/{{lng}}/i18n.json`,
  },
  supportedLngs: ["fr-FR"],
  interpolation: {
    escapeValue: false,
  },
});


export default i18n;