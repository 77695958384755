import { useEffect, useState } from "react";

import { Button, Card, Spinner } from "react-bootstrap";
import { useAuthDataContext } from "../../core/AuthDataProvider";
import { AdminOrganizationClient, AdminOrganizationListModel, CommandError, CommandErrorDetail, ValidationErrorCode } from "../../services";
import InfiniteScroll from "react-infinite-scroll-component";
import './adminOrganizations.scss'
import { OrganizationCard } from "./OrganizationCard";
import { FilterOrganizationList, IGetAdminOrganizationListFilterCommand } from "./FilterOrganizationList";
import { AddOrganizationModal } from "./addOrganizationModal";

const PAGING_SIZE = 12

export const AdminOrganizations = () => {
    useAuthDataContext();
    const _adminOrganizationClient = new AdminOrganizationClient();
    const [loading, toggleLoading] = useState(true)
    const [currentPageNum, setCurrentPageNum] = useState<number>(0)
    const [hasMore, setHasMore] = useState(true);
    const [organizationList, setOrganizationList] = useState<AdminOrganizationListModel[]>([])
    const [commandError, setCommandError] = useState<CommandError | null>(null)
    const [total, setTotal] = useState<number>(0)
    const [filter, setFilter] = useState<IGetAdminOrganizationListFilterCommand | undefined>()
    const [showAddModal, setShowAddModal] = useState(false)

    useEffect(() => {
        refresh()
    }, [filter])

    useEffect(() => {
        if (!loading) {
            setHasMore(organizationList.length < total)
        }
    }, [loading, organizationList, total])

    const fetchOrganizationList = (pageNum: number) => {
        setCommandError(null)
        _adminOrganizationClient.getOrganizationList(pageNum, PAGING_SIZE, filter?.name)
            .then((response) => {

                const data = response.organizations ?? []

                if (pageNum > 0) {
                    setOrganizationList(currentOrganizationList => [...currentOrganizationList, ...data])
                } else {
                    setOrganizationList(data)
                }

                setTotal(response.total ?? 0)
            })
            .catch((reason) => {
                if (reason instanceof CommandError) {
                    setCommandError(reason)
                } else {
                    setCommandError(new CommandError({ errors: [new CommandErrorDetail({ errorCode: ValidationErrorCode.UnexpectedError })] }))
                }
            })
            .finally(() => toggleLoading(false))
    }

    const getMoreData = () => {
        if (!loading) {
            const nextPage = currentPageNum + 1
            setCurrentPageNum(nextPage)
            fetchOrganizationList(nextPage)
        }
    }

    const refresh = () => {
        toggleLoading(true)
        setCurrentPageNum(0)
        setHasMore(true)
        setOrganizationList([])
        setTotal(0)
        fetchOrganizationList(0)
    }

    const onHideAddOrganizationModal = (needRefresh?: boolean) => {
        setShowAddModal(false)
        if (needRefresh) {
            refresh()
        }
    }

    return (
        <>
            <div className="organization-list">
                <Card className="mb-2 mt-2">
                    <Card.Body>
                        <Card.Title>
                            Filtre des organisations
                        </Card.Title>
                        <FilterOrganizationList onFilterChanged={(filter) => setFilter(filter)} />
                    </Card.Body>
                </Card>
                <Card className="mb-2 mt-2 organization-list-results">
                    <Card.Body>
                        <Card.Title className="organization-list-results-title">
                            <div>Résultats  {organizationList.length} / {total}</div>
                            <Button variant="success" onClick={() => setShowAddModal(true)}>Ajouter</Button>
                        </Card.Title>
                        {loading
                            ? <div className="spinner-container">
                                <Spinner animation="border" variant="primary" role="status" />
                            </div>
                            : <>
                                {commandError
                                    ? <div className="error">{commandError.errors![0].errorCode}</div>
                                    :
                                    <InfiniteScroll
                                        dataLength={organizationList.length}
                                        next={getMoreData}
                                        className="organization-list-results-items"
                                        hasMore={hasMore}
                                        loader={<></>}
                                    >
                                        {organizationList.length == 0
                                            ? <div className="organization-list-results-noitems">Aucune organisation</div>
                                            :
                                            organizationList.map((organization, i) =>
                                                <OrganizationCard key={organization.id} organization={organization} />
                                            )
                                        }
                                    </InfiniteScroll>
                                }
                            </>}
                    </Card.Body>
                </Card>
            </div>
            {showAddModal && <AddOrganizationModal onHide={(refresh) => onHideAddOrganizationModal(refresh)} />}
        </>
    )
}