import { DateTime } from "luxon"
import { useEffect, useState } from "react"
import { useReferentielDataContext } from "../../core/ReferentielProvider"
import { useImage } from "../../hooks/useImage"
import { AdminJobModel, JobPresentationModel, JobSalaryModel, JobStatus } from "../../services"
import './JobCard.scss'
import { JobLink } from "../../components/JobLink"

type JobCardProps = {
    job: AdminJobModel
    onEdit: () => void
}

enum FlagColor {
    Green = "green",
    Gray = "gray",
    Red = "red"
}

type JobInfoType = {
    label: string
    value: any
}

export const JobCard = ({ job, onEdit }: JobCardProps) => {
    const image = useImage()
    const referential = useReferentielDataContext()
    const [presentation, setPresentation] = useState<JobPresentationModel>()
    const [flagColor, setFlagColor] = useState<FlagColor>()
    const [infos, setInfos] = useState<JobInfoType[]>([])

    useEffect(() => {
        setPresentation(job?.presentations === undefined ? undefined : job?.presentations?.find(x => x.language == "fr_FR") ?? job?.presentations?.length > 0 ? job.presentations[0] : undefined)
    }, [job?.presentations])

    useEffect(() => {

        switch (job?.status?.status) {
            case JobStatus.Unvalidated:
                setFlagColor(FlagColor.Red)
                break;
            case JobStatus.Published:
                setFlagColor(FlagColor.Green)
                break;
            case JobStatus.Unpublished:
                setFlagColor(FlagColor.Gray)
                break;
            default:
                setFlagColor(undefined)
                break;
        }
    }, [job?.status?.status])

    useEffect(() => {
        setInfos([
            addInfos('Contrat', job?.requirements?.contractType),
            addInfos('Métier', job?.occupations?.map(x => x.occupation)?.join(', ')),
            addInfos('Adresse', job?.location?.address),
            addInfos('Code postal', job?.location?.zipCode),
            addInfos('Ville', job?.location?.city),
            addInfos('Région', job?.location?.region),
            addInfos('Télétravail', job?.teleworkingRate, (v) => mapTeleworking(v)),
            addInfos('Niveau', job?.requirements?.degreeRequired),
            addInfos('Expérience', job?.requirements?.experienceRequired),
            addInfos('Salaire', job?.salary, (v) => mapSalary(v)),
            addInfos('Salaire visible', job?.salary?.visible, (v) => v !== undefined ? v ? 'Oui' : 'Non' : undefined),
            addInfos('Avantages', job?.salary?.avantage),
        ])
    }, [job])

    const mapSalary = (value: JobSalaryModel | undefined) => {
        if (value) {
            const item = referential.jobsSalary.find(x => {
                const values = x.value!.split('-')
                if (values?.length > 1) {
                    const minValue = parseInt(values[0])
                    const maxValue = parseInt(values[1])

                    return value.minValue! >= minValue && value.minValue! <= maxValue
                }
                return false
            })

            if (item) {
                return item.label
            }
        }
        return undefined
    }

    const mapTeleworking = (value: number | undefined) => {
        switch (value) {
            case 0:
                return 'Non autorisé'
            case 25:
                return 'Entre 1 à 2 jours'
            case 50:
                return 'Mi-temps'
            case 75:
                return 'Entre 3 et 4 jours'
            case 100:
                return 'Temps complet'
            default:
                return 'Valeur non définie'
        }
    }

    function addInfos<T>(label: string, value: T, transform?: (value: T) => string | undefined): JobInfoType {

        let v: any = 'Non renseigné'

        if (value) {
            if (transform) {
                let transformResult = transform(value)

                if (transformResult) {
                    v = transformResult
                }
            } else {
                v = value
            }
        }

        return { label: label, value: v }
    }

    const formatDate = (date: Date) => DateTime.fromJSDate(date).toFormat("dd/MM/yyyy")

    return (
        <div className="job-list-item" data-job-id={job.id} onClick={(e) => onEdit()}>
            <div className="left">
                {flagColor && <div className={`left-flag ${flagColor}`}></div>}
                <div className="left-company-name">{job.company?.name}</div>
                <div className="left-title">{presentation?.title}</div>
                <div className="left-summary">{presentation?.summary}</div>
                <div className="left-infos">
                    {infos.map((info, i) => <div key={i}><span>{info.label}</span>{info.value}</div>)}
                </div>
                <ul className="left-bottom">
                    {job.createAt && job.createAt.getFullYear() > 1 && <li>{`Créée le ${formatDate(job.createAt)}`}</li>}
                    {job.status?.publishAt && <li>{`Publiée le ${formatDate(job.status.publishAt)}`}</li>}
                    {job.status?.expireAt && <li>{`Expire le ${formatDate(job.status.expireAt)}`}</li>}
                    {job?.status?.status == JobStatus.Published && <li><JobLink job={job}>Voir l'annonce</JobLink></li>}
                </ul>
            </div>
            <div className="right">
                <div className="right-image">
                    {job?.company?.backgroundImage && <img className="right-image-background" src={image(job.company.backgroundImage)} />}
                    {job?.company?.logo && <img className="right-image-logo" src={image(job.company.logo)} />}
                </div>
            </div>
        </div>
    )
}