import React from "react";

export type EditCandidateInfosModelContextType = {
    editMode: boolean
    toggleEditMode: React.Dispatch<React.SetStateAction<boolean>>
};

export const EditCandidateInfosModelContext = React.createContext<EditCandidateInfosModelContextType>(
    undefined as unknown as EditCandidateInfosModelContextType
);
