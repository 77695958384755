import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FloatingLabel, Form, FormLabel } from "react-bootstrap"
import { AsyncTypeahead } from "react-bootstrap-typeahead"
import { Controller, FieldPath, useFormContext } from "react-hook-form"
import { Config } from "../../core/Config"
import { ReferentielContextType } from "../../core/ReferentielProvider"
import { AdminJobModel, ReferentialData } from "../../services"
import { EditJobForm } from "./types"
import { EditableJobOptions } from "./useOptions"
import DatePicker from "react-datepicker"
import { useValidators } from "./useValidators"
import { forwardRef } from "react"
import { faPlus } from "@fortawesome/free-solid-svg-icons"

type EditableJobPrincipalPanelProps = {
    job: AdminJobModel
    referential: ReferentielContextType
    options: EditableJobOptions
}

export const EditableJobPrincipalPanel = ({ job, referential, options }: EditableJobPrincipalPanelProps) => {
    const { register, control, setValue } = useFormContext<EditJobForm>()
    const { degrees, experiences, skills } = options
    const { validateOccupations, validateSkills } = useValidators()

    const parseRawDate = (e: string, field: FieldPath<EditJobForm>) => {
        const date = Date.parse(e)
        if (!isNaN(date)) {
            setValue(field, new Date(date))
        }
    }

    return (
        <div className="d-flex w-100 justify-content-between gap-2 align-items-stretch editable-job-list-item-header">
            <div className="left">
                <div>Identifiant interne : {job.id}</div>
                <div>Entreprise : <a href={`${Config.frontend_url}/companies/${job.company?.directLink}`} target="_blank">{job.company?.name}</a></div>
                <FloatingLabel
                    label="Titre">
                    <Form.Control type="text" placeholder="Titre" {...register("title", { required: "Veuillez renseigner le titre" })} />
                </FloatingLabel>
                <div className="d-flex d-flex gap-2 flex-wrap">
                    <FloatingLabel
                        label="Contrat">
                        <Form.Select {...register("contractType", { required: "Veuillez sélectionner un type de contrat" })}>
                            <option value={""}>Sélectionner un contrat</option>
                            {referential.jobsContractsType.map((item, i) => <option key={i} value={item.value}>{item.label}</option>)}
                        </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel
                        label="Diplôme">
                        <Form.Select {...register("degreeRequired", { required: "Veuillez sélectionner le niveau d'étude" })}>
                            {degrees.map((item, i) => <option key={i} value={item.value}>{item.label}</option>)}
                        </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel
                        label="Expérience">
                        <Form.Select {...register("experienceRequired")}>
                            {experiences.map((item, i) => <option key={i} value={item.value}>{item.label}</option>)}
                        </Form.Select>
                    </FloatingLabel>
                </div>
                <div className="d-flex d-flex gap-2 flex-wrap">
                    <FloatingLabel
                        label="Salaire">
                        <Form.Select {...register("salary")}>
                            <option value="">Non renseigné</option>
                            {referential.jobsSalary.map((item, i) => <option key={i} value={item.value}>{item.label}</option>)}
                        </Form.Select>
                    </FloatingLabel>
                    <div className="d-flex align-items-center">
                        <Form.Check id="salaryVisible" type="switch" {...register("salaryVisible")} />
                        <FormLabel htmlFor="salaryVisible" className="pt-1">Visible</FormLabel>
                    </div>
                    <FloatingLabel
                        label="Avantages">
                        <Form.Control type="text" placeholder="Avantages" {...register("avantage")} />
                    </FloatingLabel>
                </div>
                <div className="d-flex d-flex gap-2 flex-wrap">
                    <Controller
                        control={control}
                        name="skills"
                        rules={{ validate: validateSkills }}
                        render={({
                            field: { onChange, onBlur, name, value, ref }
                        }) => (
                            <AsyncTypeahead
                                filterBy={() => true}
                                id={name}
                                onBlur={onBlur}
                                onChange={(e: any[]) => onChange(e.map(v => v?.label ? v.label : v))}
                                ref={ref}
                                isLoading={skills.loading}
                                labelKey="label"
                                multiple
                                clearButton
                                allowNew
                                newSelectionPrefix={<FontAwesomeIcon className="text-success" icon={faPlus} />}
                                minLength={2}
                                className="skills w-100"
                                selected={value}
                                onSearch={skills.search}
                                options={skills.results}
                                placeholder="Compétences"
                            >
                            </AsyncTypeahead>
                        )} />
                </div>
                <div className="d-flex gap-2">
                    <FloatingLabel
                        label="Télétravail">
                        <Form.Select {...register("teleworkingRate")}>
                            <option value={0}>Pas de télétravail</option>
                            <option value={25}>1 à 2 jours par semaine</option>
                            <option value={50}>Mi-temps</option>
                            <option value={75}>3 à 4 jours par semaine</option>
                            <option value={100}>Plein temps</option>
                        </Form.Select>
                    </FloatingLabel>
                    <Controller
                        control={control}
                        name="startDate"
                        rules={{ validate: (v) => v > new Date(2018, 1, 1) || "Veuillez renseigner la date de début de mission" }}
                        render={({
                            field: { value, onChange, ...props }
                        }) => (

                            <DatePicker
                                selected={value}
                                onChange={onChange}
                                onChangeRaw={(e) => parseRawDate(e.target.value, "startDate")}
                                dateFormat="dd/MM/yyyy"
                                className="react-datapicker__input-text"
                                customInput={
                                    <DatePickerInput label="Date de mission" />
                                }
                            />
                        )} />
                </div>
            </div>
            <div className="right">
                <FloatingLabel
                    label="Métiers (2 maximum)">
                    <Form.Select
                        multiple
                        {...register("occupations", { validate: (v) => validateOccupations(v) })}
                    >
                        {referential.jobTypesOptions.map((opt, i) => {
                            if (opt.label) {
                                return (
                                    <optgroup key={i} label={opt.label}>
                                        {opt.options!.map((child, j) => <option key={j} value={child.value}>{child.label}</option>)}
                                    </optgroup>
                                )
                            }
                            else {
                                return opt.options!.map((child, j) => <option key={j} value={child.value}>{child.label}</option>)
                            }
                        })}
                    </Form.Select>
                </FloatingLabel>
            </div>
        </div >
    )
}

const DatePickerInput = forwardRef(({ label, ...props }: any, ref: any) => (
    <FloatingLabel
        label={label}
    >
        <Form.Control
            type="text"
            ref={ref}
            {...props}
        />
    </FloatingLabel>
))
DatePickerInput.displayName = "DatePickerInput"