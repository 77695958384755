import { useState } from "react";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { DeleteCandidateCommand, IAdminCandidateResponseModel, IDeleteCandidateCommand } from "../../services";

export interface IDeleteCandidateConfirmProps {
  candidate: IAdminCandidateResponseModel;
  confirm: (command: IDeleteCandidateCommand) => void;
  cancel: () => void;
}

export function DeleteCandidateConfirm({ candidate, confirm, cancel }: IDeleteCandidateConfirmProps) {
  const [deleteInformation, setDeleteInformation] = useState<IDeleteCandidateCommand>(DeleteCandidateCommand.fromJS({}))
  return <Modal show={candidate ? true : false}>
    <Modal.Header closeButton>
      <Modal.Title>Suppression<b> {candidate.firstName} {candidate.lastName}</b></Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <p>Vous allez supprimer le candidat<b> {candidate.firstName} {candidate.lastName}</b> cette action est  <b className="text-danger">irréversible.</b> </p>
      <p>Si la candidat a été téléchargé ou a postulé à des jobs, les entreprises seront notifiées de sa suppression.</p>
      <FloatingLabel
        controlId="floatingInput"
        label="Raison de la suppression"
        className="mb-3"
      >
        <Form.Control
          className="mt-2"
          required
          value={deleteInformation?.reasonType}
          as="select"
          onChange={(e) => setDeleteInformation(s => ({ ...s, ["reasonType"]: Number.parseInt(e.target.value) }))}
        >
          <option value="">Sélectionnez la raison de la suppression</option>
          <option value="0">Inconnue</option>
          <option value="1">Mauvais profil</option>
          <option value="2">Suppression demandée</option>

        </Form.Control>
      </FloatingLabel>



      <FloatingLabel
        controlId="floatingInput"
        label="Notifier les entreprises"
        className="mb-3"
      >
        <Form.Control
          className="mt-2"
          required
          rows={6}
          as="textarea"
          value={deleteInformation?.reasonComment}
          onChange={(e) => setDeleteInformation(s => ({ ...s, ["reasonComment"]: e.target.value }))}
        >
        </Form.Control>

      </FloatingLabel>

      <Form.Check
        type="switch"
        id="custom-switch"
        label="Notifier les entreprises "
        onChange={(e) => setDeleteInformation(s => ({ ...s, ["notifyCompanies"]: e.target.checked }))}
      />
    </Modal.Body>

    <Modal.Footer>
      <Button variant="secondary" onClick={() => cancel()}>Annuler</Button>
      <Button variant="danger" onClick={() => confirm(deleteInformation)}>Confirmer la suppression</Button>
    </Modal.Footer>
  </Modal>

}