import { AdminJobModel } from "../../services"
import { Config } from "../../core/Config"
import { ReactNode } from "react"

type JobLinkProps = {
    job: AdminJobModel
    className?: string
    children: ReactNode
}

export const JobLink = ({ job, className, children }: JobLinkProps) => {
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation()
    }
    return <a href={`${Config.frontend_url}/companies/${job.company?.directLink}/jobs/${job.directLink}`} target="_blank" className={className} onClick={handleClick}>{children}</a>
}