import { faInfo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { FloatingLabel, Form, Spinner } from "react-bootstrap"
import { useFormContext } from "react-hook-form"
import { useAuthDataContext } from "../../core/AuthDataProvider"
import { ReferentielContextType } from "../../core/ReferentielProvider"
import { AdminCompanyClient, AdminJobModel, CompanyPredefinedLocationModel } from "../../services"
import { EditJobForm } from "./types"

type EditableJobLocationPanelProps = {
    job: AdminJobModel
    referential: ReferentielContextType
}

export const EditableJobLocationPanel = ({ job, referential }: EditableJobLocationPanelProps) => {
    useAuthDataContext()
    const client = new AdminCompanyClient()
    const { register, watch, trigger, setValue } = useFormContext<EditJobForm>()

    const [isFullTeleworking, setFullTeleworking] = useState(false)
    const [predefinedLocations, setPredefinedLocations] = useState<CompanyPredefinedLocationModel[]>([])
    const [loadingPredefinedLocations, setLoadingPredefinedLocations] = useState(true)
    const [selectedLocation, setSelectedLocation] = useState<CompanyPredefinedLocationModel | undefined>()

    const teleworkingRate = watch("teleworkingRate")
    const address = watch("address")
    const zipCode = watch("zipCode")
    const city = watch("city")
    const region = watch("region")

    useEffect(() => {
        setFullTeleworking(teleworkingRate == 100)
    }, [teleworkingRate])

    /**
     * Force la validation des champs d'adresse lorsque le champ teleworkingRate change
     */
    useEffect(() => {
        trigger(['zipCode', 'city', 'region'])
    }, [isFullTeleworking])

    useEffect(() => {
        const loadPredefinedLocationList = async () => {
            try {
                setLoadingPredefinedLocations(true)
                const response = await client.getPredefinedLocationList(job!.company!.id!)
                setPredefinedLocations(response.list!)
                setSelectedLocation(job.location?.predefinedLocationId ? response.list?.find(x => x.id === job.location?.predefinedLocationId) : undefined)
            } catch (error) {

            }
            finally {
                setLoadingPredefinedLocations(false)
            }
        }
        loadPredefinedLocationList()
    }, [job.company?.id])

    useEffect(() => {
        if (loadingPredefinedLocations === false) {
            console.log('selectedLocation', selectedLocation)
            if (selectedLocation) {
                setValue('predefinedLocationId', selectedLocation.id)
                setValue('address', selectedLocation.address ?? "")
                setValue('zipCode', selectedLocation.zipCode ?? "", { shouldValidate: true })
                setValue('city', selectedLocation.city ?? "", { shouldValidate: true })
                setValue('region', selectedLocation.region ?? "", { shouldValidate: true })
            } else {
                setValue('predefinedLocationId', undefined)
            }
        }
    }, [selectedLocation])

    /**
     * En cas de changement d'un champ de localisation par rapport à l'adresse prédéfinie, on déselectionne l'adresse
     */
    useEffect(() => {
        if (selectedLocation) {
            if (address != selectedLocation.address
                || zipCode != selectedLocation.zipCode
                || city != selectedLocation.city
                || region != selectedLocation.region) {
                setSelectedLocation(undefined)
            }
        }
    }, [address, zipCode, city, region])

    return (
        <div className="d-flex flex-column w-100 gap-2">
            <h6>Localisation</h6>
            {loadingPredefinedLocations ?
                <div className="spinner-container"><Spinner animation="border" variant="primary" role="status" /></div>
                : <>
                    <CompanyPredefinedLocations locations={predefinedLocations} onSelect={(location) => setSelectedLocation(location)} />
                    <div className="d-flex gap-2 flex-wrap">
                        <FloatingLabel
                            label="Adresse"
                            className="flex-grow-1">
                            <Form.Control type="text" placeholder="Adresse" {...register("address")} />
                        </FloatingLabel>
                        <FloatingLabel
                            label="Code postal"
                            className="location">
                            <Form.Control type="text" placeholder="Code postal" {...register("zipCode", { validate: { required: (v) => (v ?? "") !== "" || isFullTeleworking || "Veuillez renseigner le code postal" } })} />
                        </FloatingLabel>
                        <FloatingLabel
                            label="Ville"
                            className="location">
                            <Form.Control type="text" placeholder="Ville" {...register("city", { validate: { required: (v) => (v ?? "") !== "" || isFullTeleworking || "Veuillez renseigner la ville" } })} />
                        </FloatingLabel>
                        <FloatingLabel
                            label="Région"
                            className="location">
                            <Form.Select {...register("region", { validate: { required: (v) => (v ?? "") !== "" || isFullTeleworking || "Veuillez sélectionner la région" } })}>
                                <option value={""}>Sélectionner la région</option>
                                {referential.jobsRegions.map((item, i) => <option key={i} value={item.value}>{item.label}</option>)}
                            </Form.Select>
                        </FloatingLabel>
                    </div>
                    <div className="d-flex ps-2 gap-1 align-items-center small"><FontAwesomeIcon icon={faInfo} className="text-blue-600" /> L'adresse complète n'est pas obligatoire si l'offre est en télétravail à plein temps.</div>
                </>
            }
        </div>
    )
}

type CompanyPredefinedLocationsProps = {
    locations: CompanyPredefinedLocationModel[]
    onSelect: (location: CompanyPredefinedLocationModel | undefined) => void
}

const CompanyPredefinedLocations = ({ locations, onSelect }: CompanyPredefinedLocationsProps) => {

    const { watch } = useFormContext<EditJobForm>()

    const predefinedLocationId = watch('predefinedLocationId') ?? ""

    if (locations.length === 0) return <></>

    const getFullAddress = (location: CompanyPredefinedLocationModel) => {
        const parts = new Array<string>()

        const add = (v: string | undefined, expr?: (v: string) => string) => {
            if (v) {
                parts.push(expr ? expr(v) : v)
            }
        }

        add(location.address)
        add(location.zipCode)
        add(location.city)
        add(location.region, (v) => `(${v})`)

        return parts.join(' ')
    }

    const selectLocation = (id: string) => {
        const selectedLocation = locations.find(x => x.id === id)
        onSelect(selectedLocation)
    }

    return (
        <FloatingLabel
            label="Adresses prédéfinies de l'entreprise">
            <Form.Select value={predefinedLocationId} onChange={(e) => selectLocation(e.target.value)}>
                <option value={""}>Sélectionnez une adresse pour renseigner automatiquement les champs</option>
                {locations.map((location, i) => <option key={i} value={location.id}>{getFullAddress(location)}</option>)}
            </Form.Select>
        </FloatingLabel>
    )
}