import { useState } from "react"
import { Button, Col, Container, FloatingLabel, Form, Row } from "react-bootstrap"

export interface IGetAdminOrganizationListFilterCommand {
    name?: string
}

export type FilterOrganizationListProps = {
    onFilterChanged: (filter: IGetAdminOrganizationListFilterCommand) => void
}

export const FilterOrganizationList = ({ onFilterChanged }: FilterOrganizationListProps) => {
    const [filter, setFilter] = useState<IGetAdminOrganizationListFilterCommand>({})

    const onResetFilter = () => {
        const filter: IGetAdminOrganizationListFilterCommand = {}
        setFilter(filter)
        onFilterChanged(filter)
    }

    const onSubmitSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        onFilterChanged(filter)
    }
    
    return (
        <Container fluid>
            <Form onSubmit={onSubmitSearch}>
                <Form.Group className="mb-3">
                    <Row>
                        <Col className="mt-1" md="6" xl="3">
                            <FloatingLabel
                                label="Nom">
                                <Form.Control type="text" placeholder="Nom" value={filter?.name ?? ""} onChange={(e) => setFilter({ ...filter, name: e.target.value })} />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <div className="float-end">
                                <Button variant="outline-disabled" className="mx-1" onClick={(e) => onResetFilter()}>Réinitialiser les filtres</Button>
                                <Button variant="outline-primary" type="submit">Rechercher</Button>
                            </div>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
        </Container>
    )
}