import { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AuthDataProvider from "./core/AuthDataProvider";
import AppRoutes from "./Routes";
import { ReferentialProvider } from "./core/ReferentielProvider";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";



function App() {
  console.log("App");
  console.log(process.env);
  return (
    <Suspense fallback={<></>} >
      <I18nextProvider i18n={i18n}>
        <Router>
          <AuthDataProvider>
            <ReferentialProvider>
              <AppRoutes />
            </ReferentialProvider>
          </AuthDataProvider>
        </Router>
      </I18nextProvider>
    </Suspense>)
}

export default App;
