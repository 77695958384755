import { Config } from "../core/Config"

export const useImage = () => {
    return (img: string) => {
        const regex = />(?<Target>[0-9,]+)>(?<Env>.+)\/(?<File>.+)/
        const matches = img.match(regex)
        if (matches !== null && matches.length === 4) {
            const target = matches['groups']!.Target
            const env = matches['groups']!.Env
            const file = matches['groups']!.File

            return `${Config.api_url}/api/Misc/Image/${target}/${env}-${file}?cache=0&size=1400`
        }
        return img
    }
}