import {
    faCancel,
    faCheck,
    faDeleteLeft,
    faRemove
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import autosize from "autosize/";
import { IEditableTimeLineCardModel } from "./IEditableTimeLineCardModel";
import { TimeLineContext } from "./TimeLineContext";

type TimeLineCardEditorProps = {
    item: IEditableTimeLineCardModel
    onHide: () => void
    drag: boolean
}

export const TimeLineCardEditor = ({ item, onHide, drag }: TimeLineCardEditorProps) => {

    const { cancelCardEdition, closeCard, updateCard, deleteCard } = useContext(TimeLineContext)

    const textareaRef = useRef(null)
    const [year, setYear] = useState("")
    const [rows, setRows] = useState<number | undefined>(undefined)

    useEffect(() => {
        setYear(item?.year ?? year)
    }, [item?.year])

    useEffect(() => {
        const textSplited = item.description?.split("\n") ?? [];
        setRows(textSplited.length <= 2 ? 2 : textSplited.length);
    }, [item?.description])

    useEffect(() => {
        if (rows) {
            autosize(textareaRef.current!);
        }
    }, [rows])

    const saveAndClose = () => {
        save({ ...item, year: year }, false)
        closeCard(item.id)
        onHide()
    }

    const saveDraft = (card: IEditableTimeLineCardModel) => {
        save(card, true)
    }

    const save = (card: IEditableTimeLineCardModel, draft: boolean) => {
        updateCard(card, draft)
    }

    const hide = () => {
        cancelCardEdition(item)
        onHide()
    }

    const remove = () => {
        deleteCard(item)
        onHide()
    }

    return (
        <div className={`candidate-modal-timeline-card-editor ${drag ? "drag" : ""}`}>
            <Form.Group>
                <Row>
                    <Col>
                        <Form.Control type="text" size="sm" placeholder="Titre" onChange={(e) => saveDraft({ ...item, title: e.target.value })} className="candidate-modal-timeline-card-editor-title" value={item?.title} tabIndex={0} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Control type="text" size="sm" placeholder="Sous titre" onChange={(e) => saveDraft({ ...item, subTitle: e.target.value })} className="candidate-modal-timeline-card-editor-title" value={item?.subTitle} tabIndex={1} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Control type="text" size="sm" placeholder={`${item?.type == 'education' ? "Institution" : "Entreprise"}`} onChange={(e) => saveDraft({ ...item, organization: e.target.value })} className="candidate-modal-timeline-card-editor-title" value={item?.organization} tabIndex={1} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="candidate-modal-timeline-card-editor-year-container">
                            <Form.Control type="number" size="sm" placeholder="Année" onChange={(e) => setYear(e.target.value)} className="candidate-modal-timeline-card-editor-year" value={year} tabIndex={2} />
                            <span className="candidate-modal-timeline-card-editor-to-label">à</span>
                            <Form.Check type="switch" checked={item?.current} onChange={(e) => saveDraft({ ...item, current: e.target.checked })} className="candidate-modal-timeline-card-editor-current" tabIndex={3} />
                            <Form.Control type="number" size="sm" placeholder="Fin" onChange={(e) => saveDraft({ ...item, yearEnd: e.target.value })} className="candidate-modal-timeline-card-editor-year" value={item?.yearEnd} tabIndex={3} disabled={item?.current} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Control ref={textareaRef} as="textarea" placeholder="Description" onChange={(e) => saveDraft({ ...item, description: e.target.value })} rows={rows} value={item?.description} tabIndex={4} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Check type="switch" label={`${item?.type == 'education' ? "Formation" : "Expérience"} en cyber sécurité`} checked={item?.relevant} onChange={(e) => saveDraft({ ...item, relevant: e.target.checked })} className="candidate-modal-timeline-card-editor-relevant" tabIndex={5} />
                    </Col>
                </Row>
                <div className="candidate-modal-timeline-card-editor-button-bar">
                    <FontAwesomeIcon icon={faCheck} title="Valider les modifications" style={{ color: 'green' }} onClick={() => saveAndClose()} />
                    <FontAwesomeIcon icon={faCancel} title="Annuler les modifications" style={{ color: 'black' }} onClick={() => hide()} />
                    <FontAwesomeIcon icon={faRemove} title="Supprimer la carte" style={{ color: 'red' }} onClick={() => remove()} />
                </div>
            </Form.Group>
        </div>
    )
}