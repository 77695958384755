import { useEffect, useState } from "react"
import { Button, Col, Container, FloatingLabel, Form, Row } from "react-bootstrap"
import { IGetAdminJobListFilterCommand, JobStatus } from "../../services"

type FilterJobsProps = {
    initialFilter: IGetAdminJobListFilterCommand
    onFilterChanged: (filter: IGetAdminJobListFilterCommand) => void
}

export const FilterJobs = ({ initialFilter, onFilterChanged }: FilterJobsProps) => {
    const [filter, setFilter] = useState<IGetAdminJobListFilterCommand>(initialFilter)

    const onResetFilter = () => {
        const filter: IGetAdminJobListFilterCommand = {}
        setFilter(filter)
        onFilterChanged(filter)
    }

    const onSubmitSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        onFilterChanged(filter)
    }
    return (
        <Container fluid>
            <Form onSubmit={onSubmitSearch}>
                <Form.Group className="mb-3">
                    <Row>
                        <Col className="mt-1" md="6" xl="3">
                            <FloatingLabel
                                label="Mot clé">
                                <Form.Control type="text" placeholder="Mot clé" value={filter?.keyword ?? ""} onChange={(e) => setFilter({ ...filter, keyword: e.target.value })} />
                            </FloatingLabel>
                        </Col>
                        <Col className="mt-1" md="6" xl="3">
                            <FloatingLabel
                                label="Statut">
                                <Form.Control
                                    value={filter.status ?? ''}
                                    as="select"
                                    onChange={(e) => setFilter({ ...filter, status: e.target.value ? e.target.value as unknown as JobStatus : undefined })}
                                >
                                    <option value={''}>Sélectionner un statut</option>
                                    <option value={JobStatus.Unvalidated}>En attente de validation</option>
                                    <option value={JobStatus.Unpublished}>Non publiée</option>
                                    <option value={JobStatus.Published}>Publiée</option>
                                </Form.Control>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <div className="float-end">
                                <Button variant="outline-disabled" className="mx-1" onClick={(e) => onResetFilter()}>Réinitialiser les filtres</Button>
                                <Button variant="outline-primary" type="submit">Rechercher</Button>
                            </div>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
        </Container>
    )
}