import { ReferentielContextType } from "../../core/ReferentielProvider"
import { IReferentialData, JobOccupationModel, ReferentialData } from "../../services"

/**
 * Indique si le type de contrat est valide
 * @param referential 
 * @param v 
 * @returns 
 */
const isValidContractType = (referential: ReferentielContextType, v: string | undefined) => {
    return referential.jobsContractsType.some(x => x.value == v)
}

/**
 * Vérifie si la valeur est définie dans les options
 * @param options 
 * @param v 
 * @returns 
 */
const isValidOptions = (options: IReferentialData[], v: string | undefined) => {
    return options.some(x => x.value == v)
}

/**
 * Conserve uniquement les métiers valides
 * @param referential 
 * @param occupations 
 * @returns 
 */
const keepValidOccupations = (referential: ReferentielContextType, occupations: JobOccupationModel[] | undefined) => {
    const options = referential.jobTypesOptions.flatMap((opt) => opt.options ?? new Array<ReferentialData>())
    return occupations?.filter(x => options.some(o => o.value == x.occupation))?.map(x => x.occupation) ?? new Array<string>()
}

/**
 * Valide les métiers
 * @param v 
 * @returns 
 */
const validateOccupations = (v: string[]) => {
    if (v.length == 0) {
        return "Veuillez sélectionner au moins 1 métier"
    }

    return v.length <= 2 || "Veuillez sélectionner au maximum 2 métiers"
}

/**
 * Valide les compétences
 * @param v 
 * @returns 
 */
const validateSkills = (v: string[]) => {
    return v.length <= 8 || "Veuillez sélectionner au maximum 8 compétences"
}

export const useValidators = () => {
    return {
        isValidContractType,
        isValidOptions,
        keepValidOccupations,
        validateOccupations,
        validateSkills
    }
}